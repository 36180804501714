<template>
  <div
    class="fixed inset-0 bg-gray-900 bg-opacity-50 z-[9999] flex justify-center items-center"
  >
    <div
      class="flex flex-col bg-white rounded-3xl w-4/5 max-w-[414px] mobile:w-[100%] mobile:h-full mobile:max-w-full mobile:rounded-none absolute shadow-md text-[#797979]"
    >
      <ModalHeader @close="close" class="mobile:rounded-none" />

      <div class="p-6 px-[55px] mobile:px-6 flex flex-col items-start">
        <h2
          class="text-[16px] text-[#000] text-center w-full font-[500] mb-[12px] leading-[100%] text-[#797979]"
        >
          Восстановление пароля
        </h2>

        <div v-if="step === 1">
          <p class="text-[12px] font-[500] max-[767px]:text-[9px] mb-1">
            Введите ваш email для получения кода восстановления
          </p>
          <div class="relative w-full mb-4">
            <input
              v-model="email"
              class="w-full p-3 shadow-in rounded-lg text-sm"
              placeholder="Email"
            />
          </div>
          <div class="w-full mobile:fixed bottom-0 right-0">
            <button
              @click="requestResetCode"
              class="bg-[#4ADE80] mb-[14px] mobile:mb-0 w-full hover:bg-[#4ADE80]/40 text-white font-[500] text-[16px] h-[40px] px-4 rounded-lg"
            >
              Отправить код
            </button>
          </div>
        </div>

        <div v-if="step === 2" class="w-full">
          <p class="text-[10px] font-[500] mb-[3px]">
            Код отправлен на
            <span class="text-[#3C92C4]">{{ maskedEmail }}</span>
          </p>
          <div class="relative w-full mb-4">
            <input
              v-model="resetCode"
              class="w-full p-3 shadow-in rounded-lg text-sm"
              placeholder="Код подтверждения"
            />
          </div>
          <div class="relative w-full mb-4">
            <input
              v-model="newPassword"
              type="password"
              class="w-full p-3 shadow-in rounded-lg text-sm"
              placeholder="Новый пароль"
            />
          </div>
          <div class="relative w-full mb-4">
            <input
              v-model="confirmPassword"
              type="password"
              class="w-full p-3 shadow-in rounded-lg text-sm"
              placeholder="Подтвердите пароль"
            />
          </div>
          <p
            v-if="successMessage"
            class="text-[#4ADE80] hidden mobile:block text-[10px] mb-2"
          >
            {{ successMessage }}
          </p>
          <div class="w-full mobile:fixed bottom-0 right-0">
            <button
              @click="resetPassword"
              class="bg-[#4ADE80] mb-[14px] mobile:mb-1 w-full hover:bg-[#4ADE80]/40 text-white font-[500] text-[16px] h-[40px] px-4 rounded-lg"
            >
              Сбросить пароль
            </button>

            <p
              v-if="successMessage"
              class="text-[#4ADE80] mobile:hidden text-[10px] mb-2"
            >
              {{ successMessage }}
            </p>

            <button
              @click="goBack"
              class="bg-transparent text-nowrap h-[38px] text-[#ff9387] h-[40px] px-5 rounded border-none cursor-pointer text-base w-full shadow hover: mb-[11px] mobile:mb-0"
            >
              Назад
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import ModalHeader from "@/components/ModalDeal/ModalBlocks/ModalHeader.vue";
import axios from "axios";

const emit = defineEmits(["close", "open-login"]);

const props = defineProps({
  initialEmail: {
    type: String,
    default: "",
  },
  codeAlreadySent: {
    type: Boolean,
    default: false,
  },
});

const step = ref(1);
const email = ref(props.initialEmail);
const resetCode = ref("");
const newPassword = ref("");
const confirmPassword = ref("");
const errorMessage = ref("");
const successMessage = ref("");

const maskedEmail = computed(() => {
  if (!email.value) return "";
  const [username, domain] = email.value.split("@");
  return `${username[0]}${"*".repeat(username.length - 1)}@${domain}`;
});

onMounted(() => {
  if (props.codeAlreadySent) {
    step.value = 2;
    successMessage.value = "Код восстановления уже отправлен на ваш email";
  }
});

const goBack = () => {
  emit("open-login");
};

const close = () => {
  emit("close");
};

const requestResetCode = () => {
  axios
    .post(
      "https://backend.umbrellax.io/api/auth/forgot-password",
      { email: email.value },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        step.value = 2;
        successMessage.value = "Код восстановления отправлен на ваш email";
        errorMessage.value = "";
      } else {
        throw new Error("Не удалось отправить код восстановления");
      }
    })
    .catch((error) => {
      if (error.message === "Network Error") {
        errorMessage.value =
          "Ошибка сети. Возможно, проблема с CORS настройками на сервере.";
      } else {
        errorMessage.value =
          error.response?.data?.message ||
          "Ошибка при отправке кода. Пожалуйста, попробуйте снова.";
      }
    });
};

const resetPassword = () => {
  if (newPassword.value !== confirmPassword.value) {
    errorMessage.value = "Пароли не совпадают";
    return;
  }

  const params = new URLSearchParams({
    email: email.value,
    code: resetCode.value,
    password: newPassword.value,
    password_confirmation: confirmPassword.value,
  });

  axios
    .post(
      `https://backend.umbrellax.io/api/auth/reset-password?${params.toString()}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        successMessage.value = "Пароль успешно сброшен";
        errorMessage.value = "";
        setTimeout(() => {
          close();
        }, 3000);
      } else {
        throw new Error("Не удалось сбросить пароль");
      }
    })
    .catch((error) => {
      errorMessage.value =
        error.response?.data?.message ||
        "Ошибка при сбросе пароля. Пожалуйста, проверьте введенные данные и попробуйте снова.";
      if (error.response?.status === 401) {
        errorMessage.value =
          "Не удалось авторизовать запрос. Пожалуйста, проверьте свои учетные данные.";
      }
    });
};

defineExpose({ close });
</script>
