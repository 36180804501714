<template>
    <svg width="26" height="14" viewBox="0 0 26 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3 2.64526C2.44772 2.64526 2 3.09298 2 3.64526V10.6453H0V3.64526C0 1.98841 1.34315 0.645264 3 0.645264H9V2.64526H3Z" fill="url(#paint0_linear_831_2857)" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M26 5.64526V10.6453C26 12.3022 24.6569 13.6453 23 13.6453H17V11.6453H23C23.5523 11.6453 24 11.1976 24 10.6453V5.64526H26Z" fill="url(#paint1_linear_831_2857)" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.34004 4.70586L6.3764 5.39846V4.09976C6.3764 3.49376 5.63514 3.49376 5.63514 4.09976V5.39846L4.6715 4.70586C4.22674 4.44616 3.85611 5.22536 4.30087 5.48506L5.26451 6.17776L4.30087 6.78376C3.85611 7.13006 4.22674 7.90936 4.6715 7.56296L5.63514 6.95696V8.25566C5.63514 8.77516 6.3764 8.77516 6.3764 8.25566V6.95696L7.34004 7.56296C7.7848 7.90936 8.15543 7.13006 7.71067 6.78376L6.74703 6.17776L7.71067 5.48506C8.15543 5.22536 7.7848 4.44616 7.34004 4.70586Z" fill="url(#paint2_linear_831_2857)" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.21573 10.6454H7.45167C7.61346 10.6454 7.6674 10.5194 7.6674 10.3934V9.82646C7.6674 9.70046 7.61346 9.57446 7.45167 9.57446H4.21573C4.10786 9.57446 4 9.70046 4 9.82646V10.3934C4 10.5194 4.10786 10.6454 4.21573 10.6454Z" fill="url(#paint3_linear_831_2857)" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.34 4.70586L11.3764 5.39846V4.09976C11.3764 3.49376 10.6351 3.49376 10.6351 4.09976V5.39846L9.6715 4.70586C9.22674 4.44616 8.85611 5.22536 9.30087 5.48506L10.2645 6.17776L9.30087 6.78376C8.85611 7.13006 9.22674 7.90936 9.6715 7.56296L10.6351 6.95696V8.25566C10.6351 8.77516 11.3764 8.77516 11.3764 8.25566V6.95696L12.34 7.56296C12.7848 7.90936 13.1554 7.13006 12.7107 6.78376L11.747 6.17776L12.7107 5.48506C13.1554 5.22536 12.7848 4.44616 12.34 4.70586Z" fill="url(#paint4_linear_831_2857)" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2399 4.70586L16.2763 5.39846V4.09976C16.2763 3.49376 15.535 3.49376 15.535 4.09976V5.39846L14.5714 4.70586C14.1266 4.44616 13.756 5.22536 14.2008 5.48506L15.1644 6.17776L14.2008 6.78376C13.756 7.13006 14.1266 7.90936 14.5714 7.56296L15.535 6.95696V8.25566C15.535 8.77516 16.2763 8.77516 16.2763 8.25566V6.95696L17.2399 7.56296C17.6847 7.90936 18.0553 7.13006 17.6106 6.78376L16.6469 6.17776L17.6106 5.48506C18.0553 5.22536 17.6847 4.44616 17.2399 4.70586Z" fill="url(#paint5_linear_831_2857)" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.39298 10.6454H12.6289C12.7368 10.6454 12.8446 10.5194 12.8446 10.3934V9.82646C12.8446 9.70046 12.7368 9.57446 12.6289 9.57446H9.39298C9.28511 9.57446 9.17725 9.70046 9.17725 9.82646V10.3934C9.17725 10.5194 9.28511 10.6454 9.39298 10.6454Z" fill="url(#paint6_linear_831_2857)" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5168 10.6454H17.8067C17.9145 10.6454 18.0224 10.5194 18.0224 10.3934V9.82646C18.0224 9.70046 17.9145 9.57446 17.8067 9.57446H14.5168C14.4089 9.57446 14.355 9.70046 14.355 9.82646V10.3934C14.355 10.5194 14.4089 10.6454 14.5168 10.6454Z" fill="url(#paint7_linear_831_2857)" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.6947 10.6454H22.9846C23.0924 10.6454 23.2003 10.5194 23.2003 10.3934V9.82646C23.2003 9.70046 23.0924 9.57446 22.9846 9.57446H19.6947C19.5868 9.57446 19.479 9.70046 19.479 9.82646V10.3934C19.479 10.5194 19.5868 10.6454 19.6947 10.6454Z" fill="url(#paint8_linear_831_2857)" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M22.7454 0L24.4112 0.633561L22.3735 5.9915L20.964 7.26L20.7065 5.36093L22.7454 0ZM23.0403 0.65697L22.9361 0.931004L23.6501 1.20253L23.7543 0.928498L23.0403 0.65697ZM23.469 1.67849L22.7551 1.40696L21.296 5.24346L22.0099 5.51497L23.469 1.67849ZM21.6771 5.93319L21.2774 5.78114L21.3392 6.23724L21.6771 5.93319Z" fill="url(#paint9_linear_831_2857)" />
  <defs>
    <linearGradient id="paint0_linear_831_2857" x1="9" y1="5.64526" x2="0" y2="5.64526" gradientUnits="userSpaceOnUse">
      <stop stop-color="#6198FA" />
      <stop offset="1" stop-color="#FF1DD9" />
    </linearGradient>
    <linearGradient id="paint1_linear_831_2857" x1="26" y1="9.64526" x2="17" y2="9.64526" gradientUnits="userSpaceOnUse">
      <stop stop-color="#6198FA" />
      <stop offset="1" stop-color="#FF1DD9" />
    </linearGradient>
    <linearGradient id="paint2_linear_831_2857" x1="7.91144" y1="6.14528" x2="4.1001" y2="6.14528" gradientUnits="userSpaceOnUse">
      <stop stop-color="#6198FA" />
      <stop offset="1" stop-color="#FF1DD9" />
    </linearGradient>
    <linearGradient id="paint3_linear_831_2857" x1="7.6674" y1="10.1099" x2="4" y2="10.1099" gradientUnits="userSpaceOnUse">
      <stop stop-color="#6198FA" />
      <stop offset="1" stop-color="#FF1DD9" />
    </linearGradient>
    <linearGradient id="paint4_linear_831_2857" x1="12.9114" y1="6.14528" x2="9.1001" y2="6.14528" gradientUnits="userSpaceOnUse">
      <stop stop-color="#6198FA" />
      <stop offset="1" stop-color="#FF1DD9" />
    </linearGradient>
    <linearGradient id="paint5_linear_831_2857" x1="17.8113" y1="6.14528" x2="14" y2="6.14528" gradientUnits="userSpaceOnUse">
      <stop stop-color="#6198FA" />
      <stop offset="1" stop-color="#FF1DD9" />
    </linearGradient>
    <linearGradient id="paint6_linear_831_2857" x1="12.8446" y1="10.1099" x2="9.17725" y2="10.1099" gradientUnits="userSpaceOnUse">
      <stop stop-color="#6198FA" />
      <stop offset="1" stop-color="#FF1DD9" />
    </linearGradient>
    <linearGradient id="paint7_linear_831_2857" x1="18.0224" y1="10.1099" x2="14.355" y2="10.1099" gradientUnits="userSpaceOnUse">
      <stop stop-color="#6198FA" />
      <stop offset="1" stop-color="#FF1DD9" />
    </linearGradient>
    <linearGradient id="paint8_linear_831_2857" x1="23.2003" y1="10.1099" x2="19.479" y2="10.1099" gradientUnits="userSpaceOnUse">
      <stop stop-color="#6198FA" />
      <stop offset="1" stop-color="#FF1DD9" />
    </linearGradient>
    <linearGradient id="paint9_linear_831_2857" x1="24.4112" y1="3.63" x2="20.7065" y2="3.63" gradientUnits="userSpaceOnUse">
      <stop stop-color="#6198FA" />
      <stop offset="1" stop-color="#FF1DD9" />
    </linearGradient>
  </defs>
</svg>
</template>
