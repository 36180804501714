<template>
  <div
    v-if="isVisible"
    class="fixed top-[64px] right-[7%] mobile:top-0 mobile:right-0 mobile:left-0 mobile:w-full w-[400px] max-h-[400px] bg-[#fff]/85 rounded-lg shadow-lg z-50 overflow-hidden"
  >
    <div class="py-2 px-4 border-b flex justify-between items-center">
      <h3 class="text-[11px] font-semibold text-[#797979]">Уведомления</h3>
      <button
        @click="toggleVisibility"
        class="text-[#797979] hover:text-[#797979] text-[11px]"
      >
        Закрыть
      </button>
    </div>
    <div
      v-if="filteredNotifications.length > 0"
      class="overflow-y-auto custom-scrollbar max-h-[360px]"
    >
      <NotificationItem
        v-for="notification in filteredNotifications"
        :key="notification.id"
        :notification="notification"
        class="w-full"
      />
    </div>
    <div v-else class="p-4 text-center text-gray-500">
      Нет новых уведомлений
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";
import { useNotificationStore } from "@/stores/notificationStore";
import { useUserStore } from "@/stores/userStore";
import NotificationItem from "./NotificationItem.vue";
import { shouldShowNotification } from "@/helpers/notificationHelper";

const notificationStore = useNotificationStore();
const userStore = useUserStore();

const isVisible = ref(false);

const filteredNotifications = computed(() => {
  const userId = userStore.user?.id;
  return notificationStore.notifications
    .filter((notification) => shouldShowNotification(notification, userId))
    .sort((a, b) => {
      if (a.isRead !== b.isRead) {
        return a.isRead ? 1 : -1;
      }
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
});

onMounted(() => {
  notificationStore
    .fetchUnreadNotifications()
    .then(() => {})
    .catch((error) => {
      console.error("Failed to fetch unread notifications:", error);
    });
});

const toggleVisibility = () => {
  isVisible.value = !isVisible.value;
};

defineExpose({ toggleVisibility, isVisible });
</script>
