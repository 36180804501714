import { defineStore } from "pinia";
import { ref, computed } from "vue";

import CashMoscow from "../assets/img/bankIcons/CashMoscow.svg";
import PayAcc from "../assets/img/bankIcons/PayAcc.svg";
import AlfaIcon from "../assets/img/bankIcons/alfa.svg";
import TbankIcon from "../assets/img/bankIcons/tbank.svg";
import SberIcon from "../assets/img/bankIcons/sber.svg";
import SBPIcon from "../assets/img/bankIcons/sbp.svg";
import RaifazenIcon from "../assets/img/bankIcons/raifazen.svg";
import VTBIcon from "../assets/img/bankIcons/vtb.svg";
import RosIcon from "../assets/img/bankIcons/rosbank.svg";
import GazIcon from "../assets/img/bankIcons/gazprom.svg";
import OtkritieIcon from "../assets/img/bankIcons/otkritie.svg";
import MTSIcon from "../assets/img/bankIcons/mts.svg";
import PochtaIcon from "../assets/img/bankIcons/pochta.svg";
import AkBarsIcon from "../assets/img/bankIcons/akbars.svg";
import RoscelhozIcon from "../assets/img/bankIcons/roscelhoz.svg";
import WebmoneyIcon from "../assets/img/bankIcons/webmoney.svg";
import RussianStandartIcon from "../assets/img/bankIcons/russianstandart.svg";
import CitiIcon from "../assets/img/bankIcons/citi.svg";
import EvropaIcon from "../assets/img/bankIcons/evropa.svg";
import UralSibIcon from "../assets/img/bankIcons/uralsib.svg";
import MoneyIcon from "../assets/img/bankIcons/money.svg";
import HoumIcon from "../assets/img/bankIcons/houm.svg";
import AdvCash from "../assets/img/bankIcons/advcash.svg";
import OzonIcon from "../assets/img/bankIcons/ozon.svg";
import YandexIcon from "../assets/img/bankIcons/yandex.svg";
import Payeer from "../assets/img/bankIcons/payeer.svg";

export const useBankStore = defineStore("bank", () => {
  const banks = [
    { id: 1, name: "Внутри бирж по ID" },
    { id: 2, name: "Наличные", icon: CashMoscow },
    { id: 3, name: "Расчет. счет", icon: PayAcc },
    { id: 4, name: "Т-Банк", icon: TbankIcon },
    { id: 5, name: "Сбер", icon: SberIcon },
    { id: 6, name: "СБП", icon: SBPIcon },
    { id: 7, name: "Альфа Банк", icon: AlfaIcon },
    { id: 8, name: "Райффайзен", icon: RaifazenIcon },
    { id: 9, name: "ВТБ", icon: VTBIcon },
    { id: 10, name: "Озон Банк", icon: OzonIcon },
    { id: 11, name: "ЮMoney", icon: MoneyIcon },
    { id: 12, name: "Росбанк", icon: RosIcon },
    { id: 13, name: "Газпромбанк", icon: GazIcon },
    { id: 14, name: "Банк Открытие", icon: OtkritieIcon },
    { id: 15, name: "Яндекс Банк", icon: YandexIcon },
    { id: 16, name: "MTC-банк", icon: MTSIcon },
    { id: 17, name: "Payeer", icon: Payeer },
    { id: 18, name: "Почта Банк", icon: PochtaIcon },
    { id: 19, name: "АкБарс Банк", icon: AkBarsIcon },
    { id: 20, name: "AdvCash", icon: AdvCash },
    { id: 21, name: "Банк Хоум Кредит", icon: HoumIcon },
    { id: 22, name: "РосСельхозБанк", icon: RoscelhozIcon },
    { id: 23, name: "WebMoney", icon: WebmoneyIcon },
    { id: 24, name: "Русский стандарт", icon: RussianStandartIcon },
    { id: 25, name: "Ситибанк", icon: CitiIcon },
    { id: 26, name: "КредитЕвропаБанк", icon: EvropaIcon },
    { id: 27, name: "Банк Уралсиб", icon: UralSibIcon },
    { id: 28, name: "Душанбе Сити Банк", icon: UralSibIcon },
    { id: 29, name: "Bereke Bank", icon: UralSibIcon },
  ];

  const getBankById = (id) => banks.find((bank) => bank.id === id);
  const getBankNameById = (id) => {
    const bank = getBankById(id);
    return bank ? bank.name : "";
  };

  return {
    banks,
    getBankById,
    getBankNameById,
  };
});
