<template>
  <div class="relative" ref="profileMenuRef">
    <Profile class="cursor-pointer" @click="toggleMenu" />

    <div
      v-if="isOpen"
      class="absolute mobile:fixed mobile:left-[unset] mobile:right-0 mobile:top-0 z-[999992] left-[-278px] top-[56px] w-[320px] flex flex-col pt-[23px] bg-white rounded-b-md shadow"
      ref="menuContentRef"
    >
      <div class="px-[22px] max-[1023px]:px-[13px]">
        <div class="py-1 text-[#000] flex flex-col gap-[5px]">
          <div class="flex justify-between">
            <p
              class="text-[14px] max-[1439px]:text-[12px] text-[#797979] font-[300]"
            >
              Логин:
              <span class="text-[#000] font-[600]">{{ user?.username }}</span>
            </p>
            <button
              @click="goToTransactionsWallet"
              :class="[
                'flex items-center gap-[7px] text-[15px] justify-between max-[1439px]:text-[8px]',
                { 'text-black': $route.name === 'cryptotransactions' },
              ]"
            >
              <ArticleMethodsActiveIcon class="w-[24px] h-[24px]" />
              Кошелёк
            </button>
          </div>

          <p
            class="text-[14px] max-[1439px]:text-[12px] text-[#797979] font-[300]"
          >
            ID:
            <span class="text-[#000] font-[600]">{{ user?.id }}</span>
          </p>

          <div class="flex">
            <p
              class="text-[14px] max-[1439px]:text-[12px] text-nowrap text-[#797979] font-[300]"
            >
              Общий баланс&nbsp;
            </p>
            <span
              v-if="!hideBalance"
              class="text-[#2ecc71] text-[14px] max-[1439px]:text-[12px] font-light"
            >
              {{ calculateTotalBalance }}
            </span>
            <span
              v-else
              class="text-[#2ecc71] text-[14px] max-[1439px]:text-[12px] font-light"
              >****&nbsp;</span
            >
            <button @click="toggleBalance" class="ml-2">
              <component
                :is="hideBalance ? EyeOffIcon : EyeIcon"
                class="h-[14px] w-[14px] max-[1439px]:w-[11px] max-[1439px]:h-[11px] mr-1 text-[#797979]"
              />
            </button>
            <ArticleCurrency />
          </div>

          <div class="flex items-center w-full">
            <p
              class="text-[14px] max-[1439px]:text-[12px] text-[#797979] font-[300]"
            >
              Статус:
            </p>
            <span class="text-[14px] max-[1439px]:text-[12px] font-[500]">
              &nbsp;{{ formatUserStatus }}&nbsp;&nbsp;
            </span>
            <BuyVipBtn
              class="w-[110px] h-[26px]"
              @click="handleVipButtonClick"
            />
          </div>

          <p class="text-[12px] flex items-center gap-1">
            <template v-if="kycApplication?.status === 'finished'">
              <span class="text-[#4ADE80]">Верифицирован</span>
              <VerificationComplete class="w-[22px] h-[22px]" />
            </template>

            <template
              v-else-if="
                kycApplication?.status === 'rejected' ||
                kycApplication?.status === 'pending'
              "
            >
              <button
                @click="handleVerificationClick"
                class="text-[#ff9387] max-[767px]:text-[10px] hover:underline focus:outline-none"
              >
                {{
                  kycApplication?.status === "rejected"
                    ? "Верификация на рассмотрении"
                    : "Верификация отклонена"
                }}
              </button>
              <VerificationRejectedIcon class="w-[22px] h-[22px]" />
            </template>

            <template
              v-else-if="
                kycApplication?.status === 'new' ||
                kycApplication?.status === 'waiting'
              "
            >
              <span class="text-[#E4AF18]/80">На рассмотрении</span>
              <VerificationWaitIcon class="w-[22px] h-[22px]" />
            </template>

            <template v-else>
              <button
                @click="handleVerificationClick"
                class="text-[#0098ea] max-[767px]:text-[10px] hover:underline focus:outline-none"
              >
                Пройти верификацию
              </button>
              <VerificationIcon class="w-[22px] h-[22px]" />
            </template>
          </p>
        </div>
      </div>

      <ProfileMenuItems
        @go-to-profile="goToProfile"
        @logout="handleLogout"
        class="mb-[20px]"
      />

      <div
        class="border-t border-[rgba(121,121,121,0.5)] text-[#797979]/50 px-[7px] flex flex-col text-[8px] font-[400] items-center justify-center py-[7px]"
      >
        <span>Ознакомьтесь со всеми</span>
        <span
          class="underline text-[#717070] mb-[14px] cursor-pointer hover:text-[#515050]"
          @click="showRules"
        >
          Политиками и соглашениями
        </span>
        <div class="flex items-center gap-1">
          <span>По всем вопросам</span>
          <button
            class="underline text-[#0098EA]/50 hover:text-[#0098EA]/70 focus:outline-none relative"
            @click="copyToClipboard('support@umbrellax.io')"
          >
            support@umbrellax.io
            <span
              v-if="showCopied"
              class="absolute left-full ml-2 px-[4px] py-[2px] text-[8px] top-[-8px] text-white bg-green-500 rounded whitespace-nowrap"
            >
              Скопировано
            </span>
          </button>
        </div>
      </div>
    </div>

    <Teleport to="body">
      <Transition name="fade">
        <VerificationModal
          v-if="isModalOpen"
          :mode="modalMode"
          :kyc-status="kycApplication?.status"
          :kyc-comment="kycApplication?.comment"
          @close="closeModal"
        />
      </Transition>

      <Transition name="fade">
        <BuyVipModal v-if="isModalOpenVip" @close="closeModalVip" />
      </Transition>
    </Teleport>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useNavigationStore } from "@/stores/marketStore";
import { useUserStore } from "@/stores/userStore";
import { useVerificationStore } from "@/stores/verification";
import { useRateStore } from "@/stores/rateStore";

import Profile from "../components/Profile.vue";
import ArticleCurrency from "../components/ArticleCurrency.vue";
import BuyVipBtn from "../components/BuyVipBtn.vue";
import EyeIcon from "../components/icons/EyeIcon.vue";
import EyeOffIcon from "../components/icons/EyeOffIcon.vue";
import ProfileMenuItems from "@/components/ProfileMenuItems.vue";
import ArticleMethodsActiveIcon from "../components/icons/ArticleMethodsActiveIcon.vue";
import BuyVipModal from "../components/BuyVipModal.vue";
import VerificationIcon from "@/components/icons/VerificationIcon.vue";
import VerificationModal from "@/components/VerificationModal.vue";
import VerificationRejectedIcon from "@/components/icons/VerificationRejectedIcon.vue";
import VerificationWaitIcon from "@/components/icons/VerificationWaitIcon.vue";
import VerificationComplete from "@/components/icons/VerificationComplete.vue";

const router = useRouter();
const navigationStore = useNavigationStore();
const userStore = useUserStore();
const verificationStore = useVerificationStore();
const rateStore = useRateStore();

const { user } = storeToRefs(userStore);
const { kycApplication } = storeToRefs(verificationStore);

const profileMenuRef = ref(null);
const menuContentRef = ref(null);

const isOpen = ref(false);
const hideBalance = ref(false);
const showCopied = ref(false);
const isModalOpen = ref(false);
const isModalOpenVip = ref(false);
const modalMode = ref("create");

watch(
  () => user.value,
  (newUser) => {
    if (newUser) {
      verificationStore
        .getKycApplication()
        .then(() => {})
        .catch((error) => {
          console.error("Failed to fetch KYC application:", error);
        });
    }
  },
  { immediate: true }
);

const calculateTotalBalance = computed(() => {
  if (!user.value?.balance) return "0";
  const total = rateStore.totalBalance("USD");
  return total.toFixed(0);
});

const formatUserStatus = computed(() => {
  const statusMap = {
    newer: "Новичок",
    merchant: "Мерчант",
  };
  return statusMap[user.value?.status || "newer"] || "Новичок";
});

function toggleMenu() {
  isOpen.value = !isOpen.value;
}

function toggleBalance() {
  hideBalance.value = !hideBalance.value;
}

function handleVerificationClick() {
  if (kycApplication.value?.status === "new") {
    return;
  }

  modalMode.value = ["pending", "rejected"].includes(
    kycApplication.value?.status
  )
    ? "update"
    : "create";

  isModalOpen.value = true;
}

function handleVipButtonClick() {
  isModalOpenVip.value = true;
}

function closeModal() {
  isModalOpen.value = false;
}

function closeModalVip() {
  isModalOpenVip.value = false;
}

function goToTransactionsWallet() {
  router.push({ name: "TransactionsWallet" });
  closeMenu();
}

function goToProfile() {
  navigationStore.showUserProfile();
  closeMenu();
}

function closeMenu() {
  isOpen.value = false;
}

function handleLogout() {
  userStore
    .logout()
    .then(() => {
      closeMenu();
      router.push({ name: "main" });
    })
    .catch((error) => {
      console.error("Logout error:", error);
    });
}

function showRules() {
  navigationStore.showRules();
  closeMenu();
}

function copyToClipboard(text) {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      showCopied.value = true;
      setTimeout(() => {
        showCopied.value = false;
      }, 2000);
    })
    .catch((error) => {
      console.error("Failed to copy text:", error);
    });
}

function handleClickOutside(event) {
  if (!isOpen.value) return;

  const isClickInsideMenu = profileMenuRef.value?.contains(event.target);
  const isClickInsideContent = menuContentRef.value?.contains(event.target);
  const currencySelector = event.target.closest(".wrap");

  if (!isClickInsideMenu && !isClickInsideContent && !currencySelector) {
    closeMenu();
  }
}

function handleEscKey(event) {
  if (event.key === "Escape") {
    if (isOpen.value) closeMenu();
    if (isModalOpen.value) closeModal();
    if (isModalOpenVip.value) closeModalVip();
  }
}

onMounted(() => {
  document.addEventListener("mousedown", handleClickOutside);
  document.addEventListener("keydown", handleEscKey);

  if (user.value?.balance) {
    rateStore.setBalances(user.value.balance);
    rateStore.startAutoUpdate();
  }
});

onUnmounted(() => {
  document.removeEventListener("mousedown", handleClickOutside);
  document.removeEventListener("keydown", handleEscKey);
  rateStore.stopAutoUpdate();
});

defineEmits(["go-to-profile", "logout"]);
</script>
