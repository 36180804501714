import { defineStore } from "pinia";
import { ref } from "vue";
import { $axios, apiList } from "../api";
import { useAuthStore } from "./authStore";
import { useRouter } from "vue-router";

export const useSocialAuthStore = defineStore("socialAuth", () => {
  const authStore = useAuthStore();
  const router = useRouter();
  const isLoading = ref(false);

  const getOauthUrl = (provider) => {
    return $axios({
      url: apiList.auth.socialite[provider].url,
      method: apiList.auth.socialite[provider].getRedirectMethod,
    })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.error("OAuth URL error:", err);
        throw err;
      });
  };

  const registrationUsingSocial = (provider, data) => {
    isLoading.value = true;
    console.group(`${provider} Auth Registration`);

    return $axios({
      url: apiList.auth.socialite[provider].url,
      method:
        provider === "google"
          ? apiList.auth.socialite.google.callbackMethod
          : "POST",
      data,
    })
      .then((response) => {
        const { user, token } = response.data;

        if (token) {
          authStore.setToken(token);
        }
        if (user) {
          authStore.setUser(user);
        }

        return response.data;
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        throw error;
      })
      .finally(() => {
        isLoading.value = false;
        console.groupEnd();
      });
  };

  const onSocialButtonClicked = (provider) => {
    if (provider === "google") {
      return getOauthUrl("google").then((url) => {
        window.location.href = url;
        return Promise.resolve();
      });
    }

    if (provider === "telegram") {
      return new Promise((resolve, reject) => {
        if (!window.Telegram?.Login) {
          reject(new Error("Telegram SDK not loaded"));
          return;
        }

        window.Telegram.Login.auth(
          { bot_id: "7327427792", request_access: true },
          (data) => {
            if (!data) {
              reject(new Error("Telegram login failed"));
              return;
            }

            registrationUsingSocial("telegram", data)
              .then(resolve)
              .catch(reject);
          }
        );
      });
    }
  };

  return {
    isLoading,
    registrationUsingSocial,
    onSocialButtonClicked,
  };
});
