<template>
  <div
    class="fixed inset-0 bg-gray-900 bg-opacity-50 z-[9999] flex justify-center items-center"
  >
    <div
      class="flex flex-col bg-white rounded-3xl w-4/5 max-w-[414px] mobile:w-[100%] mobile:h-full mobile:max-w-full mobile:rounded-none absolute shadow-md text-[#797979]"
    >
    <ModalHeader @close="close" class="mobile:rounded-none"/>

      <div class="p-6 px-[55px] mobile:px-6 flex flex-col items-start">
        <h2
          class="text-[16px] text-[#000] text-center w-full font-[500] mb-[12px] leading-[100%] text-[#797979]"
        >
          Подтвердите ваш E-mail
        </h2>
        <p class="text-[12px] font-[500] max-[767px]:text-[9px] mb-1">
          Откройте письмо в вашем почтовом сервисе
        </p>

        <p class="text-[10px] font-[500] mb-[3px]">
          Код отправлен на
          <span class="text-[#3C92C4]">{{ loginData.email }}</span>
        </p>
        <div class="relative w-full">
          <input
            ref="input"
            v-model="verificationCode"
            class="w-full p-3 pr-24 shadow-in rounded-lg text-sm"
            placeholder="Код подтверждения E-mail"
            :maxlength="6"
            inputmode="numeric"
            pattern="[0-9]*"
          />
        </div>

        <p
          v-if="error"
          class="text-[#FF6663] text-[12px] mt-2 text-center w-full"
        >
          {{ error }}
        </p>

        <button
          @click="verifyLoginEmail"
          type="submit"
          class="bg-[#4ADE80] mt-4 mb-[14px] w-full hover:bg-[#4ADE80]/40 text-white font-[500] text-[16px] h-[40px] px-4 rounded-lg"
          :disabled="isVerifying"
        >
          {{ isVerifying ? "Подтверждение..." : "Подтвердить" }}
        </button>

        <button
          @click="goBack"
          class="bg-transparent text-nowrap h-[38px] text-[#ff9387] h-[40px] px-5 rounded border-none cursor-pointer text-base w-full shadow hover:shadow-md mb-[11px]"
        >
          Назад
        </button>

        <p class="text-[10px] font-[500] text-center px-[45px]">
          Не приходит код? Проверьте папку "Спам" или обратитесь в поддержку
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useAuthStore } from "@/stores/authStore";
import ModalHeader from "../ModalDeal/ModalBlocks/ModalHeader.vue";

const props = defineProps({
  loginData: {
    type: Object,
    required: true,
    validator: (value) => {
      return value.email && value.password;
    },
    default: () => ({
      email: "",
      password: "",
      login: "",
    }),
  },
});

const authStore = useAuthStore();
const verificationCode = ref("");
const isVerifying = ref(false);
const error = ref("");
const input = ref(null);

const emit = defineEmits(["close", "verified", "back"]);

const verifyLoginEmail = () => {
  isVerifying.value = true;
  error.value = "";

  console.log("Verification data:", {
    email: props.loginData.email,
    password: props.loginData.password,
    code: verificationCode.value,
  });

  authStore
    .verifyLogin(
      props.loginData.email,
      props.loginData.password,
      verificationCode.value
    )
    .then((response) => {
      if (response.success) {
        emit("verified", response);
      }
    })
    .catch((err) => {
      console.error("Verification error:", err);
      error.value = err.message || "Ошибка подтверждения. Проверьте код.";
    })
    .finally(() => {
      isVerifying.value = false;
    });
};

const close = () => emit("close");
const goBack = () => emit("back");

onMounted(() => {
  if (input.value) {
    input.value.focus();
  }

  console.log("Received loginData:", props.loginData);
});

defineExpose({ close });
</script>
