import { defineStore } from "pinia";
import { ref, computed, onMounted } from "vue";

import BinanceIcon from "../assets/img/exchange/binance.svg";
import BybitIcon from "../assets/img/exchange/bybit.svg";
import TelegramIcon from "../assets/img/exchange/telegram.svg";
import HuobiIcon from "../assets/img/exchange/huobi.svg";
import OKXIcon from "../assets/img/exchange/okx.svg";
import UpbitIcon from "../assets/img/exchange/upbit.svg";
import BingXIcon from "../assets/img/exchange/bingx.svg";
import GeminiIcon from "../assets/img/exchange/gemini.svg";
import BitgetIcon from "../assets/img/exchange/bitget.svg";
import BitfinexIcon from "../assets/img/exchange/bitfinex.svg";
import KrakenIcon from "../assets/img/exchange/kraken.svg";
import CoinbaseIcon from "../assets/img/exchange/coinbase.svg";
import KucoinIcon from "../assets/img/exchange/kucoin.svg";
import GateIcon from "../assets/img/exchange/gate.svg";
import MexcIcon from "../assets/img/exchange/mexc.svg";

export const useExchangeStore = defineStore("exchange", {
  state: () => ({
    exchanges: [
      { id: 1, name: "Иные биржи и обменники (купоны,чеки, коды)" },
      { id: 2, name: "Binance ID", icon: BinanceIcon },
      { id: 3, name: "Bybit ID", icon: BybitIcon },
      { id: 4, name: "Telegram Wallet", icon: TelegramIcon },
      { id: 5, name: "HTX(Huobi) ID", icon: HuobiIcon },
      { id: 6, name: "OKX ID", icon: OKXIcon },
      { id: 7, name: "Upbit ID", icon: UpbitIcon },
      { id: 8, name: "BingX ID", icon: BingXIcon },
      { id: 9, name: "Gemini ID", icon: GeminiIcon },
      { id: 10, name: "Bitget ID", icon: BitgetIcon },
      { id: 11, name: "Bitfinex ID", icon: BitfinexIcon },
      { id: 12, name: "Kraken ID", icon: KrakenIcon },
      { id: 13, name: "Coinbase ID", icon: CoinbaseIcon },
      { id: 14, name: "Kucoin ID", icon: KucoinIcon },
      { id: 15, name: "Gate ID", icon: GateIcon },
      { id: 16, name: "Mexc ID", icon: MexcIcon },
    ],
  }),
  getters: {
    getExchangeById: (state) => (id) =>
      state.exchanges.find((exchange) => exchange.id === id),
    getExchangeNameById: (state) => (id) => {
      const exchange = state.exchanges.find((exchange) => exchange.id === id);
      return exchange ? exchange.name : "";
    },
  },

  actions: {
    initializeStore() {
      return Promise.resolve();
    },
  },
});
