<template>
  <svg
    class="w-[16px] h-[16px]"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="3" fill="#FF6663" />
    <path
      d="M3 8.5L7 12.5L13 3.5"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
