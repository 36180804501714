import { defineStore } from "pinia";
import { $axios, apiList } from "../api";

import CashMoscow from "../assets/img/bankIcons/CashMoscow.svg";
import PayAcc from "../assets/img/bankIcons/PayAcc.svg";
import AlfaIcon from "../assets/img/bankIcons/alfa.svg";
import TbankIcon from "../assets/img/bankIcons/tbank.svg";
import SberIcon from "../assets/img/bankIcons/sber.svg";
import SBPIcon from "../assets/img/bankIcons/sbp.svg";
import RaifazenIcon from "../assets/img/bankIcons/raifazen.svg";
import VTBIcon from "../assets/img/bankIcons/vtb.svg";
import RosIcon from "../assets/img/bankIcons/rosbank.svg";
import GazIcon from "../assets/img/bankIcons/gazprom.svg";
import OtkritieIcon from "../assets/img/bankIcons/otkritie.svg";
import MTSIcon from "../assets/img/bankIcons/mts.svg";
import PochtaIcon from "../assets/img/bankIcons/pochta.svg";
import AkBarsIcon from "../assets/img/bankIcons/akbars.svg";
import RoscelhozIcon from "../assets/img/bankIcons/roscelhoz.svg";
import WebmoneyIcon from "../assets/img/bankIcons/webmoney.svg";
import RussianStandartIcon from "../assets/img/bankIcons/russianstandart.svg";
import CitiIcon from "../assets/img/bankIcons/citi.svg";
import EvropaIcon from "../assets/img/bankIcons/evropa.svg";
import UralSibIcon from "../assets/img/bankIcons/uralsib.svg";
import MoneyIcon from "../assets/img/bankIcons/money.svg";
import HoumIcon from "../assets/img/bankIcons/houm.svg";
import AdvCash from "../assets/img/bankIcons/advcash.svg";
import Payeer from "../assets/img/bankIcons/payeer.svg";
import Yandex from "../assets/img/bankIcons/yandex.svg";
import Ozon from "../assets/img/bankIcons/ozon.svg";

export const usePaymentMethodsStore = defineStore("paymentMethods", {
  state: () => ({
    methods: [],
    selectedMethods: [],
    methodsCache: {},
    loading: false,
    error: null,
    validationErrors: null,
    iconMap: {
      Наличные: CashMoscow,
      "Расчетный счет": PayAcc,
      СБП: SBPIcon,
      "Т-Банк": TbankIcon,
      "Альфа Банк": AlfaIcon,
      Сбербанк: SberIcon,
      Райффайзен: RaifazenIcon,
      ВТБ: VTBIcon,
      Росбанк: RosIcon,
      ГазпромБанк: GazIcon,
      "Банк Открытие": OtkritieIcon,
      "МТС Банк": MTSIcon,
      "Почта Банк": PochtaIcon,
      "АкБарс Банк": AkBarsIcon,
      РосСельхозБанк: RoscelhozIcon,
      WebMoney: WebmoneyIcon,
      "Русский стандарт": RussianStandartIcon,
      Ситибанк: CitiIcon,
      "Кредит Европа Банк": EvropaIcon,
      "Банк Уралсиб": UralSibIcon,
      ЮMoney: MoneyIcon,
      "Банк Хоум Кредит": HoumIcon,
      AdvCash: AdvCash,
      "Озон Банк": Ozon,
      Payeer: Payeer,
      "Яндекс Банк": Yandex,
    },
  }),

  actions: {
    fetchPaymentMethods() {
      this.loading = true;
      return $axios({
        url: apiList.paymentMethods.getAllUser.url,
        method: apiList.paymentMethods.getAllUser.method,
      })
        .then((response) => {
          this.methods = response.data.user_payment_methods.map((method) => ({
            ...method,
            icon: this.iconMap[method.name] || null,
          }));

          this.methods.forEach((method) => {
            if (method && method.id) {
              this.methodsCache[method.id] = method;
            }
          });
          return this.methods;
        })
        .catch((error) => {
          console.error("Error fetching payment methods:", error);
          this.error = "Failed to fetch payment methods";
          throw error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    fetchAllPaymentMethods() {
      this.loading = true;
      return $axios({
        url: apiList.paymentMethods.getAll.url,
        method: apiList.paymentMethods.getAll.method,
      })
        .then((response) => {
          this.allMethods = response.data.payment_methods.map((method) => ({
            ...method,
            icon: this.iconMap[method.name] || null,
          }));
          return this.allMethods;
        })
        .catch((error) => {
          this.error = "Failed to fetch all payment methods";
          throw error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getMethodById(id) {
      if (typeof id === "object" && id.payment_method_id) {
        id = id.payment_method_id;
      }

      if (typeof id !== "number" && typeof id !== "string") {
        return Promise.reject(new Error("Invalid payment method id"));
      }

      if (this.methodsCache[id]) {
        return Promise.resolve(this.methodsCache[id]);
      }

      return $axios({
        url: apiList.paymentMethods.getById.url.replace(":id", id),
        method: apiList.paymentMethods.getById.method,
      })
        .then((response) => {
          const method = response.data.user_payment_method;
          if (method && method.id) {
            this.methodsCache[method.id] = method;
          }
          return method;
        })
        .then((method) => {
          if (method) {
            method.icon = this.iconMap[method.name] || null;
          }
          return method;
        })
        .catch((error) => {
          console.error("Error fetching payment method:", error);
          return null;
        });
    },

    addMethod(newMethod) {
      this.loading = true;
      this.error = null;
      this.validationErrors = null;

      const data = {
        payment_method_id: newMethod.payment_method_id,
        currency_id: newMethod.currency_id,
        type: newMethod.type,
        extra_data: newMethod.extra_data || {},
      };

      if (newMethod.fio) data.fio = newMethod.fio;
      if (newMethod.number) data.number = newMethod.number;

      Object.keys(data).forEach((key) => {
        if (
          data[key] === undefined ||
          data[key] === "" ||
          (key === "extra_data" && Object.keys(data[key]).length === 0)
        ) {
          delete data[key];
        }
      });

      return $axios({
        url: apiList.paymentMethods.add.url,
        method: apiList.paymentMethods.add.method,
        data: data,
      })
        .then((response) => {
          const addedMethod = response.data.user_payment_method;
          const existingIndex = this.methods.findIndex(
            (m) => m.id === addedMethod.id
          );
          if (existingIndex === -1) {
            this.methods.push(addedMethod);
          } else {
            this.methods[existingIndex] = addedMethod;
          }
          return addedMethod;
        })
        .catch((error) => {
          this.handleError(error, "adding");
          throw error;
        })
        .finally(() => {
          this.loading = false;
          this.fetchPaymentMethods();
        });
    },

    updateMethod(updatedMethod) {
      this.loading = true;
      this.error = null;

      if (!updatedMethod.id) {
        this.handleError({ message: "Method ID is missing" }, "updating");
        return Promise.reject(new Error("Method ID is missing"));
      }

      const data = {
        currency_id: updatedMethod.currency_id,
        type: updatedMethod.type,
        extra_data: updatedMethod.extra_data || {},
      };

      if (updatedMethod.fio) data.fio = updatedMethod.fio;
      if (updatedMethod.number) data.number = updatedMethod.number;

      delete data.payment_method_id;

      return $axios({
        url: apiList.paymentMethods.update.url.replace(":id", updatedMethod.id),
        method: apiList.paymentMethods.update.method,
        data: data,
      })
        .then((response) => {
          const updatedData = response.data.user_payment_method;
          const index = this.methods.findIndex((m) => m.id === updatedData.id);
          if (index !== -1) {
            this.methods[index] = updatedData;
          }
          return updatedData;
        })
        .catch((error) => {
          this.handleError(error, "updating");
          throw error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    removeMethod(methodId) {
      this.loading = true;
      this.error = null;
      return $axios({
        url: apiList.paymentMethods.delete.url.replace(":id", methodId),
        method: apiList.paymentMethods.delete.method,
      })
        .then(() => {
          const index = this.methods.findIndex((m) => m.id === methodId);
          if (index !== -1) {
            this.methods.splice(index, 1);
          }
        })
        .catch((error) => {
          this.handleError(error, "removing");
          throw error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleError(error, action) {
      if (error.response) {
        if (error.response.status === 422) {
          this.validationErrors = error.response.data.errors;
          this.error = `Validation failed. Please check the form and try again.`;
        } else {
          this.error =
            error.response.data.message ||
            `An error occurred while ${action} the payment method.`;
        }
      } else {
        this.error = `An unexpected error occurred while ${action} the payment method. Please try again.`;
      }
    },

    setSelectedMethods(methods) {
      this.selectedMethods = methods;
    },

    addSelectedMethod(methodId) {
      return this.getMethodById(methodId).then((method) => {
        if (method && !this.selectedMethods.some((m) => m.id === methodId)) {
          this.selectedMethods.push(method);
        }
      });
    },

    removeSelectedMethod(methodId) {
      this.selectedMethods = this.selectedMethods.filter(
        (m) => m.id !== methodId
      );
    },

    clearSelectedMethods() {
      this.selectedMethods = [];
    },

    getAvailableMethodsForOffer(offerPaymentMethods) {
      return this.methods.filter((method) =>
        offerPaymentMethods.includes(method.payment_method_id)
      );
    },

    getMethodIconById(id) {
      const method =
        this.methodsCache[id] || this.allMethods.find((m) => m.id === id);
      return method ? method.icon : null;
    },

    getFirstAvailableMethodForOffer(offerPaymentMethods) {
      return new Promise((resolve) => {
        const method = this.methods.find((method) => {
          const includes =
            offerPaymentMethods.includes(method.payment_method_id) ||
            offerPaymentMethods.includes(String(method.payment_method_id));

          return includes;
        });

        if (method) {
          resolve(method);
        } else {
          resolve(null);
        }
      });
    },
  },
  getters: {
    userMethods: (state) => state.methods,
    availableMethods: (state) => state.allMethods,
    selectedMethods: (state) => state.selectedMethods,
    getValidationErrors: (state) => state.validationErrors,
    isLoading: (state) => state.loading,
    hasError: (state) => state.error !== null,
    getMethodIcon: (state) => (methodName) => state.iconMap[methodName] || null,
  },
});
