import { defineStore } from "pinia";
import { markRaw } from 'vue';
import IconUSDT from '../components/icons/IconUSDT.vue';
import FooterIcon from "../components/icons/FooterIcon.vue";
import IconTON from '../components/icons/IconTON.vue';

export const useCryptoCurrencyStore = defineStore("cryptoCurrency", {
  state: () => ({
    cryptocurrencies: {
      1: { name: "USDT", icon: markRaw(IconUSDT) },
      2: { name: "UMBRELLA", icon: markRaw(FooterIcon) },
      3: { name: "TON", icon: markRaw(IconTON) },
    }
  }),
  
  getters: {
    getCryptocurrencyName: (state) => (id) => {
      return state.cryptocurrencies[id]?.name || "Unknown";
    },
    getCryptocurrencyIcon: (state) => (id) => {
      return state.cryptocurrencies[id]?.icon || null;
    },
    getAllCryptocurrencies: (state) => {
      return Object.entries(state.cryptocurrencies).map(([id, data]) => ({
        id: parseInt(id),
        name: data.name,
        icon: data.icon
      }));
    }
  },
  actions: {
    getCryptocurrencyById(id) {
      const crypto = this.cryptocurrencies[id];
      return crypto ? { id: Number(id), ...crypto } : null;
    },
  },
});
