import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useCurrenciesStore } from "./currenciesStore";
import { useUserStore } from "./userStore";
import { $axios, apiList } from "../api";

export const useCurrencySelectionStore = defineStore("currency", () => {
  const currenciesStore = useCurrenciesStore();
  const userStore = useUserStore();

  const selectedCurrency = ref("RUB");
  const rates = ref(null);
  const previousRates = ref(null);
  const lastUpdate = ref(null);
  const updateInterval = 1 * 60 * 1000;
  const hideBalance = ref(false);

  const toggleBalanceVisibility = () => {
    hideBalance.value = !hideBalance.value;
    localStorage.setItem("hideBalance", hideBalance.value.toString());
  };

  const fetchRates = () => {
    const now = Date.now();
    if (lastUpdate.value && now - lastUpdate.value < updateInterval) {
      return Promise.resolve(rates.value);
    }

    return $axios
      .request(apiList.rates.getActual)
      .then((response) => {
        if (rates.value) {
          previousRates.value = { ...rates.value };
        }
        rates.value = response.data.crypto_rates;
        lastUpdate.value = now;
        return rates.value;
      })
      .catch((error) => {
        console.error("Error fetching rates:", error);
        throw error;
      });
  };

  const getPricePerCoin = (cryptoTicker) => {
    if (!rates.value?.fiat?.[cryptoTicker]?.[selectedCurrency.value]) return 0;
    return rates.value.fiat[cryptoTicker][selectedCurrency.value];
  };

  const isPriceIncreased = (cryptoTicker) => {
    if (!previousRates.value?.fiat?.[cryptoTicker]?.[selectedCurrency.value]) {
      return null;
    }

    const currentPrice = getPricePerCoin(cryptoTicker);
    const prevPrice =
      previousRates.value.fiat[cryptoTicker][selectedCurrency.value];

    return currentPrice >= prevPrice;
  };

  const convertCryptoToFiat = (amount, cryptoTicker, fiatTicker) => {
    if (!rates.value) return 0;

    const cryptoRates = rates.value.fiat[cryptoTicker];
    if (!cryptoRates) return 0;

    return amount * cryptoRates[fiatTicker];
  };

  const balancesInFiat = computed(() => {
    if (!rates.value) return [];

    return userStore.cryptoBalances.map((crypto) => ({
      ...crypto,
      fiatBalance: convertCryptoToFiat(
        crypto.amount,
        crypto.ticker,
        selectedCurrency.value
      ),
      pricePerCoin: getPricePerCoin(crypto.ticker),
      priceIncreased: isPriceIncreased(crypto.ticker),
      fiatTicker: selectedCurrency.value,
    }));
  });

  const totalBalance = computed(() => {
    if (!balancesInFiat.value.length) return 0;

    return balancesInFiat.value.reduce(
      (total, crypto) => total + crypto.fiatBalance,
      0
    );
  });

  const formattedTotalBalance = computed(() => {
    return totalBalance.value.toFixed(0);
  });

  const availableCurrencies = computed(() => {
    return currenciesStore.getAllFiatCurrencies;
  });

  const setCurrency = (currencyCode) => {
    const currency = currenciesStore.getCurrencyByCode(currencyCode);
    if (currency) {
      selectedCurrency.value = currencyCode;
    }
  };

  const startRatesUpdate = () => {
    const savedHideBalance = localStorage.getItem("hideBalance");
    if (savedHideBalance !== null) {
      hideBalance.value = savedHideBalance === "true";
    }

    fetchRates();
    setInterval(() => {
      fetchRates();
    }, updateInterval);
  };

  return {
    selectedCurrency,
    rates,
    previousRates,
    hideBalance,

    availableCurrencies,
    balancesInFiat,
    totalBalance,
    formattedTotalBalance,

    setCurrency,
    fetchRates,
    startRatesUpdate,
    toggleBalanceVisibility,
    getPricePerCoin,
    isPriceIncreased,
  };
});
