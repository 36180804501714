import { useUserStore } from "@/stores/userStore";

export const shouldShowNotification = (notification, currentUserId) => {
  if (!notification || !notification.data) {
    return false;
  }

  const userStore = useUserStore();
  const myUserId = userStore.user?.id;

  if (notification.type === "message-received") {
    const order = notification.data.order;
    if (!order) return false;

    const { buyer_id, seller_id } = order;

    const isParticipant = myUserId === buyer_id || myUserId === seller_id;

    const isOwnMessage = notification.data.user_id === myUserId;

    return isParticipant && !isOwnMessage;
  }

  if (notification.type === "order-status-change") {
    const { buyer_id, seller_id, status, prev_status } = notification.data;

    const isSeller = myUserId === seller_id;
    const isBuyer = myUserId === buyer_id;

    const showBothStatuses = [
      "dispute",
      "aborted",
      "declined",
      "waiting",
      "accepted",
      "finished",
    ];
    if (showBothStatuses.includes(status)) {
      return true;
    }

    const statusRules = {
      new: () => false,

      pending: () => notification.data.user_id !== myUserId,

      paid: () => isSeller,

      aborted: () => {
        if (prev_status === "pending" || prev_status === "accepted") {
          return true;
        }
        return false;
      },

      declined: () => {
        if (prev_status === "dispute" || prev_status === "pending") {
          return true;
        }
        return false;
      },

      waiting: () => {
        if (prev_status === "paid") {
          return true;
        }
        return false;
      },
    };

    const rule = statusRules[status];
    if (!rule) {
      return false;
    }

    return rule();
  }

  return false;
};

export const getNotificationText = (notification) => {
  if (!notification || !notification.data) {
    return "";
  }

  if (notification.type === "message-received") {
    return `Новое сообщение в сделке №${notification.data.order.number}`;
  }

  if (notification.type === "order-status-change") {
    const statusMessages = {
      pending: "Создана новая сделка",
      accepted: "Продавец принял сделку",
      paid: "Покупатель оплатил сделку",
      completed: "Сделка завершена",
      dispute: "Сделка переведена в арбитраж",
      aborted: "Сделка отменена",
      declined: "Сделка отклонена",
      waiting: "Ожидание подтверждения оплаты",
      finished: "Сделка прошла успешно",
    };

    return `${
      statusMessages[notification.data.status] || "Статус сделки изменен"
    } №${notification.data.number}`;
  }

  return "Новое уведомление";
};

export const shouldPlaySound = (notification, currentUserId) => {
  if (!notification || !notification.data) {
    return false;
  }

  if (notification.type === "message-received") {
    const userStore = useUserStore();
    return notification.data.user_id !== userStore.user?.id;
  }

  if (notification.type === "order-status-change") {
    const importantStatuses = [
      "paid",
      "completed",
      "dispute",
      "aborted",
      "declined",
      "waiting",
    ];
    return importantStatuses.includes(notification.data.status);
  }

  return false;
};
