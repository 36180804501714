<template>
  <div
    class="flex items-center gap-x-[5px] justify-start text-[#797979] cursor-not-allowed"
  >
    <span class="relative"
      >Классическая торговля
      <span class="absolute top-[-6px] right-[-15px] text-[10px] text-[#FF6663]"
        >скоро</span
      >
    </span>
  </div>
</template>

<script setup>
</script>
