import { defineStore } from "pinia";
import { ref, computed } from "vue"; 
import { $axios, apiList } from "../api";
import { useAuthStore } from "./authStore";

export const useOrdersStore = defineStore("orders", () => {
  const currentOrder = ref(null);
  const currentPost = ref(null);
  const orders = ref([]);

  const currentDealsCount = computed(() => {
    return orders.value.filter(
      (order) =>
        !["finished", "annulled", "aborted", "declined"].includes(order.status)
    ).length;
  });

  function fetchAllOrders() {
    return $axios({
      url: apiList.orders.getAll.url,
      method: apiList.orders.getAll.method,
    })
      .then((response) => {
        if (response.data && Array.isArray(response.data.orders)) {
          orders.value = response.data.orders;
        } else {
          orders.value = [];
        }
        return orders.value;
      })
      .catch((error) => {
        console.error("Error fetching orders:", error);
        orders.value = [];
        throw new Error("Failed to fetch orders");
      });
  }

  function createOrder(orderData) {
    if (
      !orderData.selectedPaymentMethod ||
      !orderData.selectedPaymentMethod.payment_method_id
    ) {
      console.error("Invalid payment method:", orderData.selectedPaymentMethod);
      return Promise.reject(new Error("Invalid payment method"));
    }

    const paymentMethodData = {
      payment_method_id: orderData.selectedPaymentMethod.payment_method_id,
      extra_data: {},
    };

    if (orderData.selectedPaymentMethod.extra_data) {
      const rawExtraData = JSON.parse(
        JSON.stringify(orderData.selectedPaymentMethod.extra_data)
      );
      paymentMethodData.extra_data = rawExtraData;
    }

    const data = {
      offer_id: orderData.postData.id,
      amount: orderData.amount,
      payment_method: paymentMethodData,
    };

    return $axios({
      url: apiList.orders.create.url,
      method: apiList.orders.create.method,
      data: data,
    })
      .then((response) => {
        currentOrder.value = response.data.order;
        return initializeOrderData(response.data.order, orderData.postData);
      })
      .catch((error) => {
        console.error("Failed to create order:", error);
        console.error("Error response:", error.response?.data);
        throw error;
      });
  }

  function initializeOrderData(orderResponse, postData) {
    return new Promise((resolve, reject) => {
      const orderData = orderResponse.order || orderResponse;

      let orderType = orderData.type || (orderData.buyer_id ? "buy" : "sell");

      if (!orderType) {
        reject(new Error("Unable to determine order type"));
        return;
      }

      currentOrder.value = {
        ...orderData,
        type: orderType,
        cryptocurrency: orderData.cryptocurrency,
        orderId: orderData.order_id,
        nationalCurrency: orderData.national_currency,
        payTime: orderData.pay_time,
        amount: orderData.amount,
        buyCommission: orderData.buy_commission,
        sellCommission: orderData.sell_commission,
        buyTotal: orderData.buy_total,
        sellTotal: orderData.sell_total,
        paymentMethod: orderData.payment_method,
        status: orderData.status,
        createdAt: orderData.created_at,
        updatedAt: orderData.updated_at,
        finishedAt: orderData.finished_at,
        buyerId: orderData.buyer_id,
        sellerId: orderData.seller_id,
        offerId: orderData.offer_id,
        number: orderData.number,
        comment: orderData.comment,
      };

      resolve(currentOrder.value);
    });
  }

  function updateOrderStatus(orderId, status) {
    if (typeof status !== "string") {
      return Promise.reject(new Error("Invalid status type"));
    }

    if (
      currentOrder.value &&
      currentOrder.value.id === orderId &&
      currentOrder.value.status === status
    ) {
      return Promise.resolve(currentOrder.value);
    }

    const authStore = useAuthStore();
    return $axios({
      url: `${apiList.orders.update.url.replace(
        ":id",
        orderId
      )}?status=${status}`,
      method: apiList.orders.update.method,
      headers: {
        Authorization: `Bearer ${authStore.token}`,
      },
    })
      .then((response) => {
        if (
          response.data.message === "Order status was successfully updated."
        ) {
          return fetchOrderById(orderId);
        } else {
          throw new Error("Failed to update order status");
        }
      })
      .then((updatedOrder) => {
        currentOrder.value = updatedOrder;
        return updatedOrder;
      })
      .catch((error) => {
        throw error;
      });
  }

  function updateOrderLocally(updatedOrder) {
    if (currentOrder.value && currentOrder.value.id === updatedOrder.id) {
      currentOrder.value = { ...currentOrder.value, ...updatedOrder };
    }

    const index = orders.value.findIndex(
      (order) => order.id === updatedOrder.id
    );
    if (index !== -1) {
      orders.value[index] = { ...orders.value[index], ...updatedOrder };
    } else {
      orders.value.push(updatedOrder);
    }

    orders.value = [...orders.value];
  }

  function fetchCurrentPost(offerId) {
    const authStore = useAuthStore();
    const authHeaders = {
      Authorization: `Bearer ${authStore.token}`,
    };

    return $axios({
      url: apiList.offers.getById.url.replace(":id", offerId),
      method: apiList.offers.getById.method,
      headers: authHeaders,
    })
      .then((response) => {
        currentPost.value = response.data;
        return currentPost.value;
      })
      .catch((error) => {
        throw new Error("Failed to fetch current post");
      });
  }

  function fetchOrderById(orderId) {
    return $axios({
      url: apiList.orders.getById.url.replace(":id", orderId),
      method: apiList.orders.getById.method,
    })
      .then((response) => {
        return initializeOrderData(response.data, null);
      })
      .catch((error) => {
        console.error("Failed to fetch order:", error);
        throw new Error("Failed to fetch order");
      });
  }

  function createDispute(orderId, reason) {
    return $axios({
      url: apiList.disputes.create.url,
      method: apiList.disputes.create.method,
      data: {
        order_id: orderId,
        reason: reason,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Failed to create dispute:", error);
        throw error;
      });
  }

  return {
    currentOrder,
    currentPost,
    orders,
    fetchAllOrders,
    createOrder,
    initializeOrderData,
    updateOrderStatus,
    fetchCurrentPost,
    fetchOrderById,
    updateOrderLocally,
    createDispute,
    currentDealsCount,
  };
});
