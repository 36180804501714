<template>
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_651_952)">
    <path d="M18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9Z" fill="#009393" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1537 5.25V6.77288H9.83362V7.7685C11.7124 7.854 13.125 8.2215 13.125 8.6625C13.125 9.10313 11.7124 9.47062 9.83362 9.55612V12.75H8.181V9.55688C6.29437 9.4725 4.875 9.10425 4.875 8.6625C4.875 8.22038 6.29513 7.85213 8.181 7.76813V6.7725H5.86087V5.25H12.1537ZM9.83362 9.17475C11.3164 9.10725 12.4144 8.86313 12.4144 8.5725C12.4144 8.28188 11.3164 8.03775 9.83212 7.97025V8.8905C9.28151 8.9193 8.72977 8.91955 8.17913 8.89125V7.96762C6.68925 8.03512 5.58412 8.27888 5.58412 8.571C5.58412 8.86313 6.68925 9.10762 8.17913 9.17475L8.27737 9.1785H8.27887C8.3775 9.18225 8.47763 9.18562 8.57888 9.18787H8.58862L8.67413 9.18937H8.69925L8.77087 9.1905H9.22988L9.30262 9.18937H9.32888L9.42037 9.1875H9.42487C9.49312 9.186 9.561 9.18413 9.62738 9.18188H9.6375L9.7275 9.1785H9.738L9.83362 9.17475Z" fill="white" />
  </g>
  <defs>
    <clipPath id="clip0_651_952">
      <rect width="18" height="18" fill="white" />
    </clipPath>
  </defs>
</svg>
</template>
