<template>
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_dd_364_5191)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 4.75C3 4.55109 3.07902 4.36032 3.21967 4.21967C3.36032 4.07902 3.55109 4 3.75 4H20.25C20.4489 4 20.6397 4.07902 20.7803 4.21967C20.921 4.36032 21 4.55109 21 4.75C21 4.94891 20.921 5.13968 20.7803 5.28033C20.6397 5.42098 20.4489 5.5 20.25 5.5H3.75C3.55109 5.5 3.36032 5.42098 3.21967 5.28033C3.07902 5.13968 3 4.94891 3 4.75ZM3 10C3 9.80109 3.07902 9.61032 3.21967 9.46967C3.36032 9.32902 3.55109 9.25 3.75 9.25H20.25C20.4489 9.25 20.6397 9.32902 20.7803 9.46967C20.921 9.61032 21 9.80109 21 10C21 10.1989 20.921 10.3897 20.7803 10.5303C20.6397 10.671 20.4489 10.75 20.25 10.75H3.75C3.55109 10.75 3.36032 10.671 3.21967 10.5303C3.07902 10.3897 3 10.1989 3 10ZM3 15.25C3 15.0511 3.07902 14.8603 3.21967 14.7197C3.36032 14.579 3.55109 14.5 3.75 14.5H20.25C20.4489 14.5 20.6397 14.579 20.7803 14.7197C20.921 14.8603 21 15.0511 21 15.25C21 15.4489 20.921 15.6397 20.7803 15.7803C20.6397 15.921 20.4489 16 20.25 16H3.75C3.55109 16 3.36032 15.921 3.21967 15.7803C3.07902 15.6397 3 15.4489 3 15.25Z" fill="white" />
  </g>
  <defs>
    <filter id="filter0_dd_364_5191" x="-1" y="0" width="26" height="20" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset />
      <feGaussianBlur stdDeviation="2" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_364_5191" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset />
      <feGaussianBlur stdDeviation="2" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
      <feBlend mode="normal" in2="effect1_dropShadow_364_5191" result="effect2_dropShadow_364_5191" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_364_5191" result="shape" />
    </filter>
  </defs>
</svg>
</template>
