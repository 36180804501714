import { defineStore } from "pinia";
import { $axios, apiList } from "../api";
import { ref, computed } from 'vue';

export const useRateStore = defineStore("rate", {
  state: () => ({
    cryptoRates: {},
    fiatRates: {},
    loading: false,
    error: null,
    updateInterval: null,
    balances: {},
  }),
  actions: {
    startAutoUpdate() {
      this.fetchAllRates();
      this.updateInterval = setInterval(() => {
        this.fetchAllRates();
      }, 60000);
    },

    stopAutoUpdate() {
      if (this.updateInterval) {
        clearInterval(this.updateInterval);
        this.updateInterval = null;
      }
    },

    setBalances(balances) {
      this.balances = balances || {};
    },

    fetchAllRates() {
      this.loading = true;
      this.error = null;

      return $axios
        .request(apiList.rates.getActual)
        .then((response) => {
          const { crypto_rates, fiat_rates } = response.data;
          this.cryptoRates = crypto_rates;
          this.fiatRates = fiat_rates;
        })
        .catch((error) => {
          console.error("Error fetching rates:", error);
          this.error = "Failed to fetch rates";
          throw error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getCryptoToFiatRate(crypto, fiat) {
      return this.cryptoRates.fiat?.[crypto]?.[fiat] || 0;
    },
  },

  getters: {
    balancesInFiat: (state) => (selectedFiat) => {
      if (!state.balances) return [];
      
      return Object.entries(state.balances).map(([ticker, amount]) => {
        const rate = state.getCryptoToFiatRate(ticker, selectedFiat);
        const fiatBalance = amount * rate;

        return {
          ticker,
          amount,
          fiatBalance,
          fiatTicker: selectedFiat,
          pricePerCoin: rate
        };
      });
    },

    totalBalance: (state) => (selectedFiat) => {
      if (!state.balances) return 0;
      
      return Object.entries(state.balances).reduce((total, [ticker, amount]) => {
        const rate = state.getCryptoToFiatRate(ticker, selectedFiat);
        return total + (amount * rate);
      }, 0);
    }
  }
});
