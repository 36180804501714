<template>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_204_168)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.01211 7C3.01211 6.55542 3.08595 6.1292 3.21773 5.72942L0.910994 3.96791C0.461423 4.88071 0.20813 5.90924 0.20813 7C0.20813 8.08982 0.461111 9.11773 0.910059 10.0299L3.21555 8.26498C3.08501 7.86707 3.01211 7.4424 3.01211 7Z" fill="#FBBC05" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.06231 2.95555C8.02813 2.95555 8.90048 3.29777 9.58589 3.85777L11.5798 1.86666C10.3648 0.808881 8.80701 0.155548 7.06231 0.155548C4.35367 0.155548 2.02575 1.70457 0.911011 3.9679L3.21775 5.72942C3.74926 4.11599 5.26434 2.95555 7.06231 2.95555Z" fill="#EB4335" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.06231 11.0444C5.26434 11.0444 3.74926 9.88399 3.21775 8.27057L0.911011 10.0318C2.02575 12.2954 4.35367 13.8444 7.06231 13.8444C8.73411 13.8444 10.3302 13.2508 11.5281 12.1386L9.33852 10.4459C8.72071 10.8351 7.94276 11.0444 7.06231 11.0444Z" fill="#34A853" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6049 7C13.6049 6.59555 13.5426 6.16 13.4491 5.75555H7.06226V8.4H10.7386C10.5548 9.3016 10.0544 9.99475 9.33846 10.4459L11.5281 12.1386C12.7864 10.9707 13.6049 9.23098 13.6049 7Z" fill="#4285F4" />
  </g>
  <defs>
    <clipPath id="clip0_204_168">
      <rect width="14" height="14" fill="white" />
    </clipPath>
  </defs>
</svg>
</template>
