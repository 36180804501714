<template>
  <button @click="$emit('click')" class="relative">
    <svg
      class="w-[30px] h-[30px]"
      width="34"
      height="36"
      viewBox="0 0 34 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_660_3736)">
        <path
          d="M9.3125 4.375C9.46898 4.18852 9.54679 3.94848 9.52946 3.70566C9.51213 3.46284 9.40103 3.23628 9.21966 3.0739C9.03828 2.91153 8.80086 2.82607 8.55761 2.83561C8.31436 2.84514 8.08436 2.94893 7.91625 3.125C6.38059 4.83781 5.36279 6.95143 4.98125 9.22C4.94596 9.46257 5.00708 9.70932 5.15151 9.90737C5.29594 10.1054 5.5122 10.239 5.75395 10.2796C5.9957 10.3201 6.24371 10.2644 6.44485 10.1242C6.64598 9.98413 6.78424 9.77082 6.83 9.53C7.15267 7.61126 8.01356 5.8236 9.3125 4.375ZM26.0838 3.125C26.0019 3.03242 25.9026 2.95693 25.7914 2.90288C25.6803 2.84883 25.5596 2.81728 25.4362 2.81005C25.3129 2.80282 25.1893 2.82004 25.0726 2.86074C24.9559 2.90143 24.8485 2.9648 24.7564 3.04718C24.6643 3.12956 24.5893 3.22934 24.5359 3.34078C24.4825 3.45222 24.4517 3.57311 24.4452 3.69651C24.4387 3.81991 24.4566 3.94338 24.498 4.05982C24.5393 4.17627 24.6033 4.28338 24.6863 4.375C25.9856 5.82349 26.8469 7.61115 27.17 9.53C27.2111 9.77533 27.348 9.99427 27.5505 10.1387C27.6508 10.2102 27.7642 10.2612 27.8842 10.2889C28.0042 10.3166 28.1285 10.3204 28.25 10.3C28.3715 10.2796 28.4878 10.2356 28.5922 10.1703C28.6966 10.105 28.7872 10.0198 28.8587 9.91947C28.9302 9.81919 28.9812 9.7058 29.0089 9.58578C29.0366 9.46577 29.0404 9.34147 29.02 9.22C28.6377 6.95118 27.6203 4.83756 26.0838 3.125Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter1_d_660_3736)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17 2.8125C14.7622 2.8125 12.6161 3.70145 11.0338 5.28379C9.45145 6.86613 8.5625 9.01224 8.5625 11.25V12.1875C8.56635 14.7365 7.62226 17.1958 5.91375 19.0875C5.81115 19.2013 5.73801 19.3386 5.70074 19.4872C5.66347 19.6358 5.66321 19.7913 5.69997 19.9401C5.73672 20.0889 5.80939 20.2263 5.9116 20.3405C6.01381 20.4547 6.14245 20.5421 6.28625 20.595C8.21625 21.3075 10.2363 21.8325 12.325 22.1488C12.278 22.7914 12.364 23.4369 12.5776 24.0448C12.7912 24.6528 13.1279 25.2102 13.5666 25.6822C14.0053 26.1542 14.5367 26.5306 15.1274 26.7881C15.7181 27.0455 16.3556 27.1783 17 27.1783C17.6444 27.1783 18.2819 27.0455 18.8726 26.7881C19.4634 26.5306 19.9947 26.1542 20.4334 25.6822C20.8721 25.2102 21.2088 24.6528 21.4224 24.0448C21.636 23.4369 21.722 22.7914 21.675 22.1488C23.735 21.8364 25.7579 21.3154 27.7125 20.5938C27.8561 20.5407 27.9845 20.4534 28.0866 20.3393C28.1886 20.2252 28.2612 20.0879 28.2979 19.9393C28.3346 19.7908 28.3345 19.6354 28.2973 19.487C28.2602 19.3385 28.1873 19.2013 28.085 19.0875C26.3769 17.1957 25.4333 14.7363 25.4375 12.1875V11.25C25.4375 9.01224 24.5486 6.86613 22.9662 5.28379C21.3839 3.70145 19.2378 2.8125 17 2.8125ZM14.1875 22.5C14.1875 22.4575 14.1875 22.4163 14.19 22.375C16.0595 22.5435 17.9405 22.5435 19.81 22.375L19.8125 22.5C19.8125 23.2459 19.5162 23.9613 18.9887 24.4887C18.4613 25.0162 17.7459 25.3125 17 25.3125C16.2541 25.3125 15.5387 25.0162 15.0113 24.4887C14.4838 23.9613 14.1875 23.2459 14.1875 22.5Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_660_3736"
          x="0.971481"
          y="2.80844"
          width="32.0614"
          height="15.5045"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_660_3736"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_660_3736"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_660_3736"
          x="1.67259"
          y="2.8125"
          width="30.6527"
          height="32.3658"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_660_3736"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_660_3736"
            result="shape"
          />
        </filter>
      </defs>
    </svg>

    <div
      v-if="totalNotificationsCount > 0"
      class="absolute -top-[3px] -right-[2px] w-[14px] h-[14px] bg-[#06C6FF] rounded-full flex items-center justify-center"
    >
      <span class="text-white text-[9px] font-bold">{{
        totalNotificationsCount
      }}</span>
    </div>
  </button>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useNotificationStore } from "@/stores/notificationStore";
import { useUserStore } from "@/stores/userStore";
import { computed } from "vue";
import { shouldShowNotification } from "@/helpers/notificationHelper";

const notificationStore = useNotificationStore();
const userStore = useUserStore();
const { notifications } = storeToRefs(notificationStore);

const totalNotificationsCount = computed(() => {
  return notifications.value.filter((notification) => {
    const isNotNew =
      notification.type === "message-received"
        ? notification.data.order.status.toUpperCase() !== "NEW"
        : notification.data.status.toUpperCase() !== "NEW";

    const shouldShow = shouldShowNotification(notification, userStore.user?.id);

    return isNotNew && shouldShow;
  }).length;
});

defineEmits(["click"]);
</script>
