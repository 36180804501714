<template>
  <div class="flex flex-col gap-2 mobile:gap-1 mb-1">
    <CheckboxItem
      v-model="formDataLocal.not_us_resident"
      id="notUSResident"
      required
      @update:modelValue="updateFormData('not_us_resident', $event)"
    >
      Я не являюсь гражданином или налоговым резидентом США. Не нахожусь и не
      торгую на территории США. *
    </CheckboxItem>

    <CheckboxItem
      v-model="formDataLocal.agree_to_terms"
      id="agreeToTerms"
      required
      @update:modelValue="updateFormData('agree_to_terms', $event)"
    >
      Я соглашаюсь с
      <RouterLink :to="{ name: 'userAgreement' }" class="text-blue-500"
        >Пользовательским соглашением</RouterLink
      >
      и
      <RouterLink :to="{ name: 'privacyPolicy' }" class="text-blue-500"
        >Политикой конфиденциальности</RouterLink
      >
      *
    </CheckboxItem>

    <CheckboxItem
      v-model="formDataLocal.agree_to_marketing"
      id="agreeToMarketing"
      required
      @update:modelValue="updateFormData('agree_to_marketing', $event)"
    >
      Я согласен на получение рекламных и любых других сообщений по всем каналам
      связи
    </CheckboxItem>

    <CheckboxItem
      v-model="formDataLocal.agree_to_asset_use"
      id="agreeToAssetUse"
      required
      @update:modelValue="updateFormData('agree_to_asset_use', $event)"
    >
      Я согласен на предоставление своих активов для использования в иных сферах
      деятальности и инвестирование, в том числе в высокорискованные активы, как
      внутри, так и вовне криптобиржи без отчета и уведомлений, и не нахожусь
      под юрисдикцией стран, где это запрещено*
    </CheckboxItem>

    <CheckboxItem
      v-model="formDataLocal.agree_to_price_regulation"
      id="agreeToPriceRegulation"
      required
      @update:modelValue="updateFormData('agree_to_price_regulation', $event)"
    >
      Я согласен, что криптобиржа может регулировать цену при необходимости на
      некоторые токены, помимо рыночных механизмов для защиты от угроз и
      уязвимостей и иных целей, и не нахожусь под юрисдикцей стран, где это
      запрещено*
    </CheckboxItem>
  </div>
  <p v-if="hasCheckboxError" class="text-[9px] text-[#FF9387]">
    Вы должны согласиться с условиями
  </p>
</template>

<script setup>
import { defineProps, defineEmits, reactive, watch, computed } from "vue";
import { RouterLink } from "vue-router";
import CheckboxItem from "./CheckboxItem.vue";

const props = defineProps({
  formData: {
    type: Object,
    required: true,
  },
  errors: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(["update:formData"]);

const formDataLocal = reactive({
  not_us_resident: props.formData.not_us_resident || false,
  agree_to_terms: props.formData.agree_to_terms || false,
  agree_to_marketing: props.formData.agree_to_marketing || false,
  agree_to_asset_use: props.formData.agree_to_asset_use || false,
  agree_to_price_regulation: props.formData.agree_to_price_regulation || false,
});

const hasCheckboxError = computed(() => {
  return Object.keys(props.errors).some((key) =>
    [
      "not_us_resident",
      "agree_to_terms",
      "agree_to_marketing",
      "agree_to_asset_use",
      "agree_to_price_regulation",
    ].includes(key)
  );
});

watch(
  () => props.formData,
  (newValue) => {
    Object.keys(formDataLocal).forEach((key) => {
      formDataLocal[key] = newValue[key] || false;
    });
  },
  { deep: true }
);

const updateFormData = (key, value) => {
  formDataLocal[key] = value;
  emit("update:formData", {
    ...props.formData,
    not_us_resident: formDataLocal.not_us_resident,
    agree_to_terms: formDataLocal.agree_to_terms,
    agree_to_marketing: formDataLocal.agree_to_marketing,
    agree_to_asset_use: formDataLocal.agree_to_asset_use,
    agree_to_price_regulation: formDataLocal.agree_to_price_regulation,
  });
};
</script>
