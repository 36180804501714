// stores/authFormsStore.js
import { defineStore } from "pinia";

export const useAuthFormsStore = defineStore("authForms", {
  state: () => ({
    isVisible: false,
    currentForm: null,
    initialEmail: "",
    registrationType: null,
  }),

  actions: {
    showLoginForm() {
      this.currentForm = "login";
      this.isVisible = true;
    },

    showRegisterForm(email = "") {
      this.currentForm = "register";
      this.initialEmail = email;
      this.isVisible = true;
    },

    showResetPassword() {
      this.currentForm = "reset-password";
      this.isVisible = true;
    },

    showGoogleTelegramRegistration(type) {
      this.currentForm = "google-telegram";
      this.registrationType = type;
      this.isVisible = true;
    },

    close() {
      this.isVisible = false;
      this.currentForm = null;
      this.initialEmail = "";
      this.registrationType = null;
    },
  },
});
