<template>
  <svg
    width="33"
    height="35"
    viewBox="0 0 33 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_204_209)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.0402 23.5473C26.2328 22.4266 27.1829 21.0733 27.8318 19.5709C28.4806 18.0685 28.8144 16.449 28.8125 14.8125C28.8125 8.18481 23.4402 2.8125 16.8125 2.8125C10.1848 2.8125 4.81251 8.18481 4.81251 14.8125C4.81061 16.449 5.14441 18.0685 5.79325 19.5709C6.44209 21.0733 7.39219 22.4266 8.58481 23.5473C10.8087 25.6481 13.7532 26.8166 16.8125 26.8125C19.8718 26.8166 22.8163 25.6481 25.0402 23.5473ZM9.60635 21.9657C10.4704 20.8847 11.567 20.0122 12.8145 19.413C14.0621 18.8138 15.4285 18.5034 16.8125 18.5048C18.1965 18.5034 19.5629 18.8138 20.8105 19.413C22.058 20.0122 23.1546 20.8847 24.0186 21.9657C23.0759 22.918 21.9535 23.6735 20.7165 24.1886C19.4795 24.7037 18.1525 24.9681 16.8125 24.9663C15.4725 24.9681 14.1456 24.7037 12.9085 24.1886C11.6715 23.6735 10.5491 22.918 9.60635 21.9657ZM21.4279 11.1202C21.4279 12.3443 20.9416 13.5182 20.0761 14.3838C19.2105 15.2493 18.0366 15.7356 16.8125 15.7356C15.5884 15.7356 14.4145 15.2493 13.5489 14.3838C12.6834 13.5182 12.1971 12.3443 12.1971 11.1202C12.1971 9.89611 12.6834 8.72217 13.5489 7.85662C14.4145 6.99107 15.5884 6.50481 16.8125 6.50481C18.0366 6.50481 19.2105 6.99107 20.0761 7.85662C20.9416 8.72217 21.4279 9.89611 21.4279 11.1202Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_204_209"
        x="0.8125"
        y="2.8125"
        width="32"
        height="32"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_204_209"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_204_209"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
