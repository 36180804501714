<template>
  <div
    class="pt-[15px] border-b pb-[10px] max-[1439px]:pt-[10px] max-[1439px]:pb-[10px] max-[767px]:pt-[10px] max-[767px]:pb-[10px]"
  >
    <h2
      class="flex items-center justify-start mb-[5px] text-[14px] max-[1439px]:text-[12px] max-[767px]:text-[12px] text-nowrap text-[#797979]/50 pl-[45px] max-[1439px]:pl-[30px] max-[767px]:!pl-[50px]"
    >
      <span class="flex items-center">
        <ArticleMarketIcon
          class="w-6 h-6 mr-1.5 max-[1439px]:w-4 max-[1439px]:h-4 max-[767px]:w-6 max-[767px]:h-6"
        />
        P2P торговля
      </span>
    </h2>

    <ul
      class="flex flex-col items-start justify-between text-[#000] font-[300] text-[14px] max-[1439px]:text-[12px] max-[767px]:text-[14px]"
    >
      <li class="w-full">
        <MarketP2P
          class="flex pl-[65px] max-[1439px]:pl-[30px] max-[767px]:!pl-[50px] w-full py-[7px]"
          :class="getItemClass('main')"
          @click="navigateTo('main')"
        />
      </li>
      <li class="w-full">
        <MarketMy
          class="flex pl-[65px] max-[1439px]:pl-[30px] max-[767px]:!pl-[50px] w-full py-[7px]"
          :class="getItemClass('myAds')"
          @click="navigateTo('myAds')"
        />
      </li>
      <li class="w-full">
        <MarketHistory
          class="flex pl-[65px] max-[1439px]:pl-[30px] max-[767px]:!pl-[50px] w-full py-[7px]"
          :class="getItemClass('history')"
          @click="navigateTo('history')"
        />
      </li>
      <li class="w-full">
        <MarketSetup
          class="flex pl-[65px] max-[1439px]:pl-[30px] max-[767px]:!pl-[50px] w-full py-[7px]"
          :class="getItemClass('settings')"
          @click="navigateTo('settings')"
        />
      </li>
      <li class="flex items-center">
        <TableLeads
          class="flex pl-[65px] max-[1439px]:pl-[30px] max-[767px]:!pl-[50px] py-[7px] w-full"
          :class="getItemClass('', true)"
          @click="() => {}"
        />
      </li>
    </ul>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
import { useNavigationStore } from "../stores/marketStore";
import MarketMy from "./MarketMy.vue";
import MarketSetup from "./MarketSetup.vue";
import MarketHistory from "./MarketHistory.vue";
import TableLeads from "./TableLeads.vue";
import MarketP2P from "./MarketP2P.vue";
import ArticleMarketIcon from "./icons/ArticleMarketIcon.vue";

const router = useRouter();
const route = useRoute();
const navigationStore = useNavigationStore();

const getItemClass = (name, isComingSoon = false) => [
  "transition duration-300 ease-in-out",
  isComingSoon
    ? "text-[#797979] cursor-not-allowed"
    : route.name === name
    ? " bg-gray-100"
    : " hover:bg-gray-100",
];

const navigateTo = (name, isComingSoon = false) => {
  if (!isComingSoon) {
    navigationStore.setView(name);
    router.push({ name: name }).catch((err) => {
      if (err.name !== "NavigationDuplicated") {
        throw err;
      }
    });
  }
};
</script>
