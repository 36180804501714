<template>
  <LoginForm
    v-if="currentForm === 'login'"
    @close="closeForm"
    @open-register="showRegisterForm"
    @open-register-google-telegram="showRegisterGoogleTelegram"
    @open-reset="showResetPassword"
    @login-success="handleLoginSuccess"
    @open-email-verification="handleLoginVerificationRequired"
  />
  <RegisterForm
    v-if="currentForm === 'register'"
    ref="registerFormRef"
    @close="closeForm"
    @open-login="showLoginForm"
    @open-register-google-telegram="showRegisterGoogleTelegram"
    @registration-success="handleRegistrationSuccess"
  />
  <RegisterGoogleTelegram
    v-if="currentForm === 'google-telegram'"
    :registrationType="registrationType"
    @close="closeForm"
    @open-login="showLoginForm"
  />
  <ResetPassword
    v-if="currentForm === 'reset-password'"
    @close="closeForm"
    @open-login="showLoginForm"
  />
  <ConfirmEmail
    v-if="currentForm === 'confirm-email'"
    :email="registeredEmail"
    @close="closeForm"
    @verified="handleEmailVerified"
    @back="handleConfirmEmailBack"
  />
  <LoginVerification
    v-if="currentForm === 'login-verification'"
    :loginData="{
      email: loginEmail,
      password: loginPassword,
      login: loginEmail,
    }"
    @close="closeForm"
    @verified="handleLoginVerified"
    @back="showLoginForm"
  />
</template>

<script setup>
import { ref, watch, onMounted, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import LoginForm from "./LoginForm.vue";
import RegisterForm from "./RegisterForm.vue";
import RegisterGoogleTelegram from "./RegisterGoogleTelegram.vue";
import ResetPassword from "./ResetPassword.vue";
import ConfirmEmail from "./ConfirmEmail.vue";
import LoginVerification from "./LoginVerification.vue";

const props = defineProps({
  initialForm: String,
  initialEmail: {
    type: String,
    default: "",
  },
});

const route = useRoute();
const router = useRouter();

const currentForm = ref(null);
const registrationType = ref("");
const registeredEmail = ref("");
const loginEmail = ref("");
const loginPassword = ref("");
const registerFormRef = ref(null);

const showLoginForm = () => updateForm("login");
const showRegisterForm = (email = "") => {
  updateForm("register");
  if (email) {
    nextTick(() => {
      if (registerFormRef.value) {
        registerFormRef.value.setPrefillEmail(email);
      }
    });
  }
};

const showResetPassword = () => updateForm("reset-password");
const showRegisterGoogleTelegram = (type) => {
  registrationType.value = type;
  updateForm("google-telegram");
};

const updateForm = (formName) => {
  currentForm.value = formName;
  router.push({ query: { ...route.query, form: formName } });
};

const closeForm = () => {
  currentForm.value = null;
  loginEmail.value = "";
  loginPassword.value = "";
  router.push({ query: { ...route.query, form: undefined } });
  emit("close");
};

const handleRegistrationSuccess = (email) => {
  registeredEmail.value = email;
  updateForm("confirm-email");
};

const handleEmailVerified = () => {
  showLoginForm();
};

const handleConfirmEmailBack = () => {
  showRegisterForm();
};

const handleLoginVerificationRequired = ({ email, password }) => {
  loginEmail.value = email;
  loginPassword.value = password;
  updateForm("login-verification");
};

const handleLoginVerified = (result) => {
  emit("login-success", result);
  closeForm();
};

const emit = defineEmits(["close", "login-success"]);

onMounted(() => {
  currentForm.value = props.initialForm || null;
  if (props.initialEmail && props.initialForm === "register") {
    nextTick(() => {
      if (registerFormRef.value) {
        registerFormRef.value.setPrefillEmail(props.initialEmail);
      }
    });
  }
});

watch(
  () => route.query.form,
  (newForm) => {
    currentForm.value = newForm || null;
  }
);

defineExpose({
  showLoginForm,
  showRegisterForm,
  showRegisterGoogleTelegram,
  showResetPassword,
  closeForm,
});
</script>
