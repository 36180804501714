<template>
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 8.33333C7.20889 8.33333 9 6.69156 9 4.66667C9 2.64178 7.20889 1 5 1C2.79111 1 1 2.64178 1 4.66667C1 5.60178 1.38178 6.45467 2.01022 7.10222C2.20222 7.30089 2.33911 7.56444 2.27067 7.83155C2.19572 8.12363 2.05546 8.39489 1.86044 8.62489C2.01651 8.65292 2.17477 8.6669 2.33333 8.66667C2.90311 8.66667 3.43111 8.488 3.86444 8.18355C4.22444 8.28133 4.60578 8.33333 5 8.33333Z"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
