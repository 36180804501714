<template>
  <button @click="showSettings" class="w-full text-left">Настройки оплаты</button>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useNavigationStore } from "../stores/marketStore";

const router = useRouter();
const navigationStore = useNavigationStore();

function showSettings() {
  navigationStore.setView('settings');
  router.push({ name: 'settings' }).catch(err => {
    if (err.name !== 'NavigationDuplicated') {
      throw err;
    }
  });
}
</script>
