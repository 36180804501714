<template>
  <div class="w-full flex flex-col items-center mobile:w-auto">
    <Entry
      @open-login="openLogin"
      @open-register="openRegister"
      class="stroke-white text-[14px] mb-[8px] mobile:mb-2"
    />
    <div class="flex flex-row gap-x-[10px]">
      <TelegramEntry
        @click="() => handleSocialAuth('telegram')"
        class="cursor-pointer"
      />
      <GoogleEntry
        @click="() => handleSocialAuth('google')"
        class="cursor-pointer"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useAuthStore } from "@/stores/authStore";
import { useSocialAuthStore } from "@/stores/socialAuthStore";
import { useAuthFormsStore } from "@/stores/authFormsStore";
import { useRouter } from "vue-router";
import Entry from "./Entry.vue";
import GoogleEntry from "./GoogleEntry.vue";
import TelegramEntry from "./TelegramEntry.vue";

const authStore = useAuthStore();
const authFormsStore = useAuthFormsStore();
const router = useRouter();
const socialAuthStore = useSocialAuthStore();

const openLogin = () => {
  authFormsStore.showLoginForm();
};

const openRegister = () => {
  authFormsStore.showRegisterForm();
};

const handleSocialAuth = (provider, userData = null) => {
  socialAuthStore
    .onSocialButtonClicked(provider, userData)
    .then((result) => {
      if (result?.token) {
        router.push("/p2p");
      }
    })
    .catch((err) => {
      console.error("Auth error:", err);
    });
};

onMounted(() => {
  const script = document.createElement("script");
  script.src = "https://telegram.org/js/telegram-widget.js";
  script.async = true;
  document.head.appendChild(script);

  window.onTelegramAuth = (user) => {
    if (user) {
      handleSocialAuth("telegram", user);
    }
  };
});
</script>
