import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { $axios, apiList } from "../api";
import { useBankStore } from "./bankStore";
import { useUserStore } from "./userStore";
import { useOrdersStore } from "./ordersStore";
import {
  shouldShowNotification,
  getNotificationText,
  shouldPlaySound,
} from "@/helpers/notificationHelper";

export const useNotificationStore = defineStore("notification", () => {
  const bankStore = useBankStore();
  const userStore = useUserStore();
  const ordersStore = useOrdersStore();

  const notifications = ref([]);
  const popupNotifications = ref([]);
  const isPopupVisible = ref(false);

  const processApiNotification = (notification) => {
    if (notification.type === "message-received") {
      return {
        id: notification.id,
        createdAt: new Date(notification.created_at),
        isRead: notification.read_at !== null,
        isPopupClosed: false,
        type: "message-received",
        title: notification.data.title,
        order: {
          number: notification.data.data.order.number,
          id: notification.data.data.order.id,
        },
        data: notification.data.data,
      };
    } else if (notification.type === "order-status-change") {
      return {
        id: notification.id,
        createdAt: new Date(notification.created_at),
        isRead: notification.read_at !== null,
        isPopupClosed: false,
        type: "order-status-change",
        title: notification.data.title,
        order: notification.data.data,
        data: notification.data.data,
      };
    } else {
      return {
        id: notification.id,
        createdAt: new Date(notification.created_at),
        isRead: notification.read_at !== null,
        isPopupClosed: false,
        type: notification.type,
        title: notification.data.title,
        data: notification.data.data,
      };
    }
  };

  const processWebSocketNotification = (notification) => {
    if (notification.type === "order-status-change") {
      return {
        id: notification.id,
        createdAt: new Date(),
        isRead: false,
        isPopupClosed: false,
        type: "order-status-change",
        title: notification.title,
        order: notification.data,
        data: notification.data,
      };
    }

    return {
      id: notification.id,
      createdAt: new Date(),
      isRead: false,
      isPopupClosed: false,
      type: notification.type,
      title: notification.title,
      data: notification.data,
    };
  };

  const fetchUnreadNotifications = () => {
    return $axios
      .request(apiList.auth.getUser)
      .then((response) => {
        const notificationsData = response.data.user.notifications.data.filter(
          (notification) => notification.read_at === null
        );

        notifications.value = notificationsData.map((notification) =>
          processApiNotification(notification)
        );

        return notifications.value;
      })
      .catch((error) => {
        console.error("Error fetching unread notifications:", error);
        throw error;
      });
  };

  const handleWebSocketNotification = (notification) => {
    if (notification && typeof notification === "object" && notification.type) {
      if (shouldShowNotification(notification, userStore.user?.id)) {
        if (notification.type === "order-status-change") {
          ordersStore.updateOrderLocally(notification.data);
        }

        const processedNotification =
          processWebSocketNotification(notification);


        notifications.value.unshift(processedNotification);
        popupNotifications.value.unshift(processedNotification);
        isPopupVisible.value = true;

        if (shouldPlaySound(processedNotification, userStore.user?.id)) {
          playNotificationSound();
        }
      }
    }
  };

  const addNotification = (notification) => {
    if (shouldShowNotification(notification, userStore.user?.id)) {
      let processedNotification = processWebSocketNotification(notification);
      notifications.value.unshift(processedNotification);
      popupNotifications.value.unshift(processedNotification);
      isPopupVisible.value = true;
    }
  };

  const userNotifications = computed(() => {
    const userId = userStore.user?.id;
    return notifications.value.filter((n) => shouldShowNotification(n, userId));
  });

  const sortedNotifications = computed(() => {
    return [...userNotifications.value].sort(
      (a, b) => b.createdAt - a.createdAt
    );
  });

  const hasUnreadNotifications = computed(() => {
    return userNotifications.value.some((n) => !n.isRead);
  });

  const unreadNotificationsCount = computed(() => {
    return userNotifications.value.filter((n) => !n.isRead).length;
  });

  const removeNotification = (id) => {
    notifications.value = notifications.value.filter((n) => n.id !== id);
    popupNotifications.value = popupNotifications.value.filter(
      (n) => n.id !== id
    );
  };

  const closePopupNotification = (id) => {
    const index = popupNotifications.value.findIndex((n) => n.id === id);
    if (index !== -1) {
      popupNotifications.value.splice(index, 1);
    }
  };

  const setPopupVisibility = (isVisible) => {
    isPopupVisible.value = isVisible;
  };

  const closeAllPopupNotifications = () => {
    popupNotifications.value = [];
  };

  const markAsRead = (ids) => {
    const idsArray = [ids].flat().map((id) => id.toString());

    return $axios
      .put("/users/notifications/read", {
        ids: idsArray,
      })
      .then((response) => {
        idsArray.forEach((id) => {
          const notification = notifications.value.find((n) => n.id === id);
          if (notification) {
            notification.isRead = true;
          }
        });
        return response.data;
      })
      .catch((error) => {
        console.error("Error marking as read:", error);
        if (error.response) {
          console.error("Error response data:", error.response.data);
        }
        throw error;
      });
  };

  return {
    notifications,
    popupNotifications,
    isPopupVisible,
    addNotification,
    removeNotification,
    closePopupNotification,
    setPopupVisibility,
    handleWebSocketNotification,
    userNotifications,
    sortedNotifications,
    markAsRead,
    hasUnreadNotifications,
    unreadNotificationsCount,
    closeAllPopupNotifications,
    fetchUnreadNotifications,
  };
});
