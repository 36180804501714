import { defineStore } from "pinia";
import { $axios, apiList } from "../api";
import { markRaw } from "vue";

import AedIcon from "../assets/value/aed.svg";
import AmdIcon from "../assets/value/amd.svg";
import ArsIcon from "../assets/value/ars.svg";
import AznIcon from "../assets/value/azn.svg";
import BdtIcon from "../assets/value/bdt.svg";
import BrlIcon from "../assets/value/brl.svg";
import BynIcon from "../assets/value/byn.svg";
import CopIcon from "../assets/value/cop.svg";
import EtbIcon from "../assets/value/etb.svg";
import EurIcon from "../assets/value/eur.svg";
import GelIcon from "../assets/value/gel.svg";
import IdrIcon from "../assets/value/idr.svg";
import InrIcon from "../assets/value/inr.svg";
import KesIcon from "../assets/value/kes.svg";
import KgsIcon from "../assets/value/kgs.svg";
import KztIcon from "../assets/value/kzt.png";
import LkrIcon from "../assets/value/lkr.svg";
import NgnIcon from "../assets/value/ngn.svg";
import RubIcon from "../assets/value/rub.png";
import ThbIcon from "../assets/value/thb.svg";
import TryIcon from "../assets/value/try.svg";
import UahIcon from "../assets/value/uah.svg";
import UgxIcon from "../assets/value/ugx.svg";
import UzsIcon from "../assets/value/uzs.svg";
import VesIcon from "../assets/value/ves.svg";
import VndIcon from "../assets/value/vnd.svg";
import ZarIcon from "../assets/value/zar.svg";
import PHPIcon from "../assets/value/php.svg";
import PKRIcon from "../assets/value/pkr.svg";
import EGPIcon from "../assets/value/egp.svg";

import IconUSDT from "../components/icons/IconUSDT.vue";
import FooterIcon from "../components/icons/FooterIcon.vue";
import IconTON from "../components/icons/IconTON.vue";
import IconBTC from "../components/icons/IconBTC.vue";
import IconETH from "../components/icons/IconETH.vue";

import Tron from "../assets/img/net/tron.svg";
import BEP from "../assets/img/net/bep.svg";
import TON from "../assets/img/net/ton.svg";
import BTC from "../assets/img/net/btc.svg";
import ETH from "../assets/img/net/eth.svg";

export const useCurrenciesStore = defineStore("currencies", {
  state: () => ({
    currencies: {
      crypto: [],
      fiat: [],
    },
    networks: [],
    isLoading: false,
    error: null,
    fiatIcons: {
      RUB: markRaw(RubIcon),
      EUR: markRaw(EurIcon),
      KZT: markRaw(KztIcon),
      AED: markRaw(AedIcon),
      BYN: markRaw(BynIcon),
      TRY: markRaw(TryIcon),
      UAH: markRaw(UahIcon),
      INR: markRaw(InrIcon),
      ARS: markRaw(ArsIcon),
      BRL: markRaw(BrlIcon),
      NGN: markRaw(NgnIcon),
      VND: markRaw(VndIcon),
      THB: markRaw(ThbIcon),
      IDR: markRaw(IdrIcon),
      PHP: markRaw(PHPIcon),
      PKR: markRaw(PKRIcon),
      EGP: markRaw(EGPIcon),
      ZAR: markRaw(ZarIcon),
      UZS: markRaw(UzsIcon),
      GEL: markRaw(GelIcon),
      AZN: markRaw(AznIcon),
      AMD: markRaw(AmdIcon),
      KGS: markRaw(KgsIcon),
      COP: markRaw(CopIcon),
      BDT: markRaw(BdtIcon),
      LKR: markRaw(LkrIcon),
      ETB: markRaw(EtbIcon),
      KES: markRaw(KesIcon),
      UGX: markRaw(UgxIcon),
      VES: markRaw(VesIcon),
    },
    cryptoIcons: {
      USDT: markRaw(IconUSDT),
      UMBRELLA: markRaw(FooterIcon),
      TON: markRaw(IconTON),
      ETH: markRaw(IconETH),
      BTC: markRaw(IconBTC),
    },
    cryptoNets: {
      1: { name: "TRC-20", icon: markRaw(Tron) },
      2: { name: "ERC-20", icon: markRaw(ETH) },
      3: { name: "BEP-20", icon: markRaw(BEP) },
      4: { name: "TON", icon: markRaw(TON) },
      5: { name: "BTC", icon: markRaw(BTC) },
    },
  }),

  getters: {
    getCryptoData: (state) =>
      state.currencies.crypto.map((crypto) => ({
        id: crypto.id,
        ticker: crypto.ticker,
        icon: state.cryptoIcons[crypto.ticker] || null,
      })),

    getFiatData: (state) =>
      state.currencies.fiat.map((fiat) => ({
        id: fiat.id,
        name: fiat.name,
        ticker: fiat.ticker,
        icon: state.fiatIcons[fiat.ticker] || null,
      })),

    getNetworksData: (state) =>
      state.networks.map((network) => ({
        id: network.id,
        name: network.name,
        icon: state.cryptoNets[network.id]?.icon || null,
      })),

    getAllCryptoCurrencies: (state) => state.currencies.crypto,
    getAllFiatCurrencies: (state) => state.currencies.fiat,
  },

  actions: {
    fetchCurrencies() {
      this.isLoading = true;
      this.error = null;

      return $axios
        .get(apiList.currencies.getAll.url)
        .then((response) => {
          this.currencies = response.data.currencies;
          this.networks = response.data.networks;

          this.currencies.fiat = this.currencies.fiat.map((currency) => ({
            ...currency,
            icon: this.fiatIcons[currency.ticker] || null,
          }));

          this.currencies.crypto = this.currencies.crypto.map((currency) => ({
            ...currency,
            icon: this.cryptoIcons[currency.ticker] || null,
          }));

          this.networks = this.networks.map((network) => ({
            ...network,
            icon: this.cryptoNets[network.id]?.icon || null,
          }));
        })
        .catch((error) => {
          this.error =
            error.message || "An error occurred while fetching currencies";
          console.error("Error fetching currencies:", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getCurrencyById(id) {
      const allCurrencies = [
        ...this.currencies.crypto,
        ...this.currencies.fiat,
      ];
      return allCurrencies.find((currency) => currency.id === id);
    },

    getCurrencyByCode(code) {
      const allCurrencies = [
        ...this.currencies.crypto,
        ...this.currencies.fiat,
      ];
      return allCurrencies.find((currency) => currency.ticker === code);
    },

    getNetworkById(id) {
      return this.networks.find((network) => network.id === id);
    },

    getCryptocurrencyById(id) {
      const crypto = this.currencies.crypto.find((c) => c.id === id);
      return crypto
        ? { ...crypto, icon: this.cryptoIcons[crypto.ticker] || null }
        : null;
    },

    getCryptoNetById(id) {
      const cryptoNet = this.cryptoNets[id];
      return cryptoNet ? { id: Number(id), ...cryptoNet } : null;
    },
  },
});
