<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.7625 6.32C10.1625 3.23583 13.9125 1.25 18.125 1.25C18.2908 1.25 18.4497 1.31585 18.5669 1.43306C18.6842 1.55027 18.75 1.70924 18.75 1.875C18.75 6.08833 16.7642 9.8375 13.68 12.2383C13.8083 13.0419 13.7607 13.8637 13.5405 14.647C13.3203 15.4304 12.9328 16.1566 12.4047 16.7757C11.8766 17.3947 11.2205 17.8918 10.4816 18.2327C9.74274 18.5736 8.93871 18.75 8.125 18.75C7.95924 18.75 7.80027 18.6842 7.68306 18.5669C7.56585 18.4497 7.5 18.2908 7.5 18.125V14.6825C6.68994 14.0428 5.95771 13.3103 5.31833 12.5H1.875C1.70924 12.5 1.55027 12.4342 1.43306 12.3169C1.31585 12.1997 1.25 12.0408 1.25 11.875C1.24993 11.0612 1.42645 10.2571 1.76735 9.51816C2.10826 8.77922 2.60544 8.12304 3.22459 7.59493C3.84374 7.06681 4.57011 6.67932 5.35356 6.45921C6.13702 6.2391 6.9589 6.1916 7.7625 6.32ZM12.5 5.625C12.0027 5.625 11.5258 5.82254 11.1742 6.17417C10.8225 6.52581 10.625 7.00272 10.625 7.5C10.625 7.99728 10.8225 8.47419 11.1742 8.82582C11.5258 9.17746 12.0027 9.375 12.5 9.375C12.9973 9.375 13.4742 9.17746 13.8258 8.82582C14.1775 8.47419 14.375 7.99728 14.375 7.5C14.375 7.00272 14.1775 6.52581 13.8258 6.17417C13.4742 5.82254 12.9973 5.625 12.5 5.625Z"
      fill="#4ADE80"
      fill-opacity="0.9"
    />
    <path
      d="M7.72305 6.56687L7.86902 6.59019L7.9598 6.47353C10.3145 3.44761 13.9929 1.5 18.125 1.5C18.2245 1.5 18.3198 1.53951 18.3902 1.60984C18.4605 1.68016 18.5 1.77554 18.5 1.875C18.5 6.00793 16.5524 9.68553 13.5264 12.0411L13.4098 12.1318L13.4331 12.2777C13.5557 13.0456 13.5102 13.8308 13.2998 14.5794C13.0894 15.3279 12.7191 16.0219 12.2145 16.6134C11.7099 17.205 11.0829 17.68 10.3769 18.0057C9.67085 18.3314 8.90256 18.5 8.12501 18.5C8.02554 18.5 7.93016 18.4605 7.85984 18.3902C7.78951 18.3198 7.75 18.2245 7.75 18.125V14.6825V14.5614L7.65494 14.4863C6.86022 13.8587 6.14186 13.1401 5.51459 12.3451L5.43952 12.25H5.31833H1.875C1.77554 12.25 1.68016 12.2105 1.60984 12.1402C1.53951 12.0698 1.5 11.9745 1.5 11.875V11.875C1.49994 11.0974 1.6686 10.329 1.99436 9.62289C2.32011 8.91679 2.7952 8.28978 3.38683 7.78513C3.97846 7.28049 4.67255 6.91022 5.42118 6.69989C6.16982 6.48956 6.95517 6.44418 7.72305 6.56687ZM12.5 5.375C11.9364 5.375 11.3959 5.59888 10.9974 5.9974C10.5989 6.39591 10.375 6.93641 10.375 7.5C10.375 8.06358 10.5989 8.60409 10.9974 9.0026C11.3959 9.40112 11.9364 9.625 12.5 9.625C13.0636 9.625 13.6041 9.40112 14.0026 9.0026C14.4011 8.60409 14.625 8.06358 14.625 7.5C14.625 6.93641 14.4011 6.39591 14.0026 5.9974C13.6041 5.59888 13.0636 5.375 12.5 5.375Z"
      stroke="#797979"
      stroke-opacity="0.15"
      stroke-width="0.5"
    />
    <path
      d="M4.38324 14.3684C4.44907 14.3194 4.50458 14.2578 4.54661 14.1873C4.58864 14.1167 4.61637 14.0386 4.62821 13.9574C4.64005 13.8761 4.63577 13.7933 4.61561 13.7137C4.59546 13.6341 4.55982 13.5593 4.51074 13.4934C4.46166 13.4276 4.40009 13.3721 4.32956 13.3301C4.25902 13.288 4.1809 13.2603 4.09965 13.2485C4.0184 13.2366 3.93561 13.2409 3.85601 13.2611C3.77641 13.2812 3.70157 13.3169 3.63574 13.3659C2.99648 13.8415 2.49942 14.4827 2.19831 15.2204C1.89721 15.958 1.80353 16.7639 1.92741 17.5509C1.94755 17.682 2.00888 17.8033 2.10251 17.8972C2.19614 17.9912 2.31722 18.0529 2.44824 18.0734C3.23535 18.1972 4.04133 18.1034 4.77897 17.8022C5.51661 17.5009 6.15782 17.0037 6.63324 16.3643C6.68391 16.2986 6.721 16.2236 6.74236 16.1434C6.76372 16.0633 6.76892 15.9797 6.75766 15.8976C6.7464 15.8154 6.7189 15.7363 6.67676 15.6649C6.63463 15.5935 6.57871 15.5311 6.51225 15.4815C6.44579 15.4319 6.37012 15.3961 6.28966 15.376C6.2092 15.3559 6.12555 15.3521 6.04358 15.3647C5.96162 15.3773 5.88298 15.406 5.81224 15.4493C5.74151 15.4926 5.68009 15.5495 5.63158 15.6168C5.34096 16.0076 4.96289 16.325 4.52761 16.5435C4.09234 16.762 3.61195 16.8756 3.12491 16.8751C3.12491 15.8501 3.61824 14.9393 4.38324 14.3684Z"
      fill="#4ADE80"
      fill-opacity="0.7"
    />
    <path
      d="M4.2338 14.168L4.23373 14.1681C3.40814 14.7841 2.87491 15.768 2.87491 16.8751V17.1249L3.12467 17.1251C3.65074 17.1256 4.16962 17.0029 4.63977 16.7669C5.10993 16.5309 5.5183 16.1881 5.83219 15.766L5.83221 15.766L5.83432 15.763C5.86343 15.7227 5.90028 15.6885 5.94272 15.6626C5.98516 15.6366 6.03235 15.6193 6.08153 15.6118C6.13071 15.6042 6.1809 15.6065 6.22917 15.6186C6.27745 15.6306 6.32285 15.6521 6.36273 15.6819L6.51225 15.4815L6.36273 15.6819C6.4026 15.7117 6.43616 15.7491 6.46144 15.7919L6.67676 15.6649L6.46144 15.7919C6.48672 15.8348 6.50322 15.8822 6.50997 15.9315C6.51673 15.9808 6.51361 16.031 6.50079 16.079C6.48798 16.1271 6.46572 16.1722 6.43532 16.2115L6.43529 16.2115L6.43262 16.2151C5.98441 16.8179 5.37988 17.2867 4.68445 17.5707C3.98901 17.8547 3.22915 17.9432 2.48708 17.8265L2.48701 17.8265C2.40839 17.8141 2.33575 17.7771 2.27957 17.7207C2.22339 17.6644 2.18659 17.5916 2.17451 17.513L2.17437 17.5121C2.05757 16.7701 2.14589 16.0103 2.42977 15.3148C2.71365 14.6194 3.18227 14.0148 3.78495 13.5665L3.78518 13.5664C3.82469 13.5369 3.86961 13.5155 3.91738 13.5034C3.96515 13.4913 4.01484 13.4888 4.0636 13.4959C4.11236 13.503 4.15925 13.5196 4.20158 13.5448C4.24392 13.5701 4.28087 13.6034 4.31032 13.6429L4.50982 13.4941L4.31032 13.6429C4.33978 13.6824 4.36117 13.7273 4.37326 13.7751L4.61067 13.715L4.37326 13.7751C4.38536 13.8229 4.38793 13.8725 4.38082 13.9213C4.37372 13.9701 4.35708 14.017 4.33185 14.0593C4.30662 14.1016 4.27331 14.1386 4.2338 14.168Z"
      stroke="#797979"
      stroke-opacity="0.15"
      stroke-width="0.5"
    />
  </svg>
</template>
