<template>
  <div
    class="flex flex-col items-center mb-2.5 cursor-pointer"
    :class="containerClass"
  >
    <div class="flex items-center mobile:text-[12px]">
      <EntrySvg class="mobile:w-[16px] mobile:h-[16px]"/>
      <p>
        <span
          @click.stop="openLoginForm"
          class="hover:border-b hover:border-current"
          >Войти</span
        >
        |
        <span
          @click.stop="openRegisterForm"
          class="hover:border-b hover:border-current"
          >Регистрация</span
        >
      </p>
    </div>
    <slot v-if="showSlot">
      <p class="text-[12px] mobile:text-[10px]">или зарегистрируйтесь в 1 клик</p>
    </slot>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import EntrySvg from "@/components/icons/Entry-svg.vue";

defineProps({
  showSlot: {
    type: Boolean,
    default: false,
  },
  containerClass: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["openLogin", "openRegister"]);

const openLoginForm = (event) => {
  event.preventDefault();
  emit("openLogin");
};

const openRegisterForm = (event) => {
  event.preventDefault();
  emit("openRegister");
};
</script>
