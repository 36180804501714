import "./assets/main.css";
import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import { useAuthStore } from "./stores/authStore";
import { useUserStore } from "./stores/userStore";
import { useNotificationStore } from "./stores/notificationStore";
import { useNavigationStore } from "./stores/marketStore";
import { usePostsStore } from "./stores/postsStore";
import { useFilterStore } from "./stores/filterStore";
import { useCurrenciesStore } from "./stores/currenciesStore";
import { useExchangeStore } from "./stores/exchangeStore";
import { useOrdersStore } from "./stores/ordersStore";
import { useChatStore } from "./stores/chatStore";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

const pinia = createPinia();
const app = createApp(App);

// Устанавливаем плагины
app.use(pinia);
app.use(router);

// Инициализируем приложение
function initializeApp() {
  // Монтируем приложение перед использованием сторов
  app.mount("#app");

  // Теперь инициализируем сторы
  const authStore = useAuthStore();
  const userStore = useUserStore();
  const postsStore = usePostsStore();
  const filterStore = useFilterStore();
  const currenciesStore = useCurrenciesStore();
  const navigationStore = useNavigationStore();
  const exchangeStore = useExchangeStore();
  const ordersStore = useOrdersStore();

  return authStore
    .initializeAuth()
    .catch((error) => {
      console.debug("Auth initialization error:", error);
      return null;
    })
    .then(() => {
      if (authStore.token) {
        return safeInitialize(userStore.fetchUser(), "fetchUser");
      }
      return null;
    })
    .then((userData) => {
      return Promise.all([
        safeInitialize(currenciesStore.fetchCurrencies(), "fetchCurrencies"),
        initializeStore(postsStore, "initializeStore"),
        safeInitialize(filterStore.initializeFilters(), "initializeFilters"),
        initializeStore(navigationStore, "initializeView"),

        ...(userData
          ? [
              safeInitialize(exchangeStore.initializeStore(), "exchangeStore"),
              safeInitialize(ordersStore.fetchAllOrders(), "fetchAllOrders"),
            ]
          : []),

        Promise.resolve(userData),
      ]).catch((error) => {
        console.debug("Initialization chain error:", error);
        return [null, null, null, null, userData];
      });
    })
    .then((results) => {
      const userData = results[results.length - 1];

      if (postsStore.allPosts) {
        filterStore.applyFilters(postsStore.allPosts);
      }

      if (userData) {
        userStore.setAuthenticated(true);

        // Проверяем наличие необходимых параметров для WebSocket
        if (
          import.meta.env.VITE_REVERB_APP_KEY &&
          import.meta.env.VITE_REVERB_HOST &&
          import.meta.env.VITE_REVERB_PORT
        ) {
          return initializeWebSocket(userData.id).catch((error) => {
            console.debug("WebSocket initialization error:", error);
            return null;
          });
        }
        return null;
      }
    })
    .catch((error) => {
      if (error?.response?.status !== 401) {
        console.warn("Non-401 initialization error:", error);
      }
    });
}

function safeInitialize(promise, name = "unknown") {
  return Promise.resolve()
    .then(() => promise)
    .catch((error) => {
      if (error?.response?.status === 401) {
        return null;
      }
      console.warn(`Error in ${name}:`, error);
      return null;
    });
}

function initializeStore(store, methodName) {
  if (!store || !store[methodName]) {
    console.warn(`Store or method ${methodName} not found`);
    return Promise.resolve(null);
  }

  return Promise.resolve()
    .then(() => store[methodName]())
    .catch((error) => {
      if (error?.response?.status === 401) {
        return null;
      }
      console.warn(`Error in ${methodName}:`, error);
      return null;
    });
}

function initializeWebSocket(authUserId) {
  const token = localStorage.getItem("token");
  const chatStore = useChatStore();
  const notificationStore = useNotificationStore();

  window.Pusher = Pusher;
  window.Echo = new Echo({
    broadcaster: "reverb",
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT,
    wssPort: import.meta.env.VITE_REVERB_PORT,
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? "https") === "https",
    enabledTransports: ["ws", "wss"],
    authEndpoint: "https://backend.umbrellax.io/broadcasting/auth",
    auth: {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
    disableStats: true, // Отключает отправку статистики Pusher серверу
    encrypted: false, // Отключает дополнительное шифрование, так как уже используется TLS
  });

  return new Promise((resolve, reject) => {
    if (!window.Echo) {
      reject(new Error("Echo initialization failed"));
      return;
    }

    const pusher = window.Echo.connector.pusher;
    pusher.connection.bind("connected", () => {
      setupWebSocketListeners(authUserId);

      chatStore
        .initializeWebSocket()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          console.error("Failed to initialize chat WebSocket:", error);
          reject(error);
        });
    });

    pusher.connection.bind("error", (err) => {
      console.error("Pusher connection error:", err);
      reject(err);
    });
  });
}

function setupWebSocketListeners(authUserId) {
  if (!window.Echo) return; // Добавляем проверку

  const notificationStore = useNotificationStore();

  window.Echo.private(`users.${authUserId}`)
    .notification((notification) => {
      notificationStore.handleWebSocketNotification(notification);
    })
    .listen(
      ".Illuminate\\Notifications\\Events\\BroadcastNotificationCreated",
      (event) => {
        notificationStore.handleWebSocketNotification(event.notification);
      }
    )
    .error((error) => {
      console.error("WebSocket error:", error);
    });
}

initializeApp();
