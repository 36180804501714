<template>
  <div
    v-if="shouldDisplayNotification"
    class="bg-inherit flex gap-3 rounded-lg mobile:rounded-t-0 max-w-[400px] max-h-[100px] px-[14px] py-[5px] transition-all duration-300"
    :class="{ absolute: isPopup }"
  >
    <div class="">
      <component :is="notificationIcon" class="w-[30px] h-[30px]" />
    </div>
    <div class="w-full">
      <div class="flex items-start justify-between gap-[20px] w-full">
        <div class="flex items-center justify-between w-full">
          <span
            class="font-normal text-[#797979] leading-none text-[11px] mb-1"
          >
            <template v-if="notification.type === 'message-received'">
              Новое сообщение в сделке
              <span class="font-bold">{{
                notification.data.order.number
              }}</span>
            </template>
            <template v-else>
              Сделка
              <span class="font-bold">{{ notification.data.number }}</span>
              {{ getStatusText(notification.data.status) }}
            </template>
          </span>
        </div>
        <button
          @click="closeNotification(notification.id)"
          class="text-[#797979] hover:text-[#797979] leading-none text-[11px]"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 11L11 1M1 1L11 11"
              stroke="#797979"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
      <template v-if="notification.type !== 'message-received'">
        <p class="text-[#797979] leading-none text-[11px]">
          {{ isUserBuyer(notification.data) ? "Вы покупаете" : "Вы продаете" }}
          <span class="font-bold"
            >{{ notification.data.amount }}
            {{ getCryptoCurrency(notification.data) }}</span
          >
          за
          <span class="font-bold"
            >{{ notification.data.price }}
            {{ getNationalCurrency(notification.data) }}</span
          >.
        </p>
        <p class="text-[#797979] leading-none text-[11px] mb-2">
          Метод оплаты:
          {{
            getBankNameById(notification.data.payment_method.payment_method_id)
          }}
        </p>
        <div class="flex justify-start items-center space-x-2 text-[11px]">
          <template
            v-if="
              isPendingOrder(notification.data) &&
              !isOrderCreator(notification.data)
            "
          >
            <button
              @click="accept(notification.data.id)"
              class="justify-center w-[70px] flex items-center bg-[#4ADE80] text-white rounded h-[18px] hover:bg-[#4ADE80]/40 text-[10px]"
            >
              Принять
            </button>
            <button
              @click="reject(notification.data.id)"
              class="justify-center w-[70px] flex items-center bg-white text-[#FF6663] shadow rounded h-[18px] hover:bg-red-50 text-[10px]"
            >
              Отклонить
            </button>
          </template>
          <template v-else>
            <button
              @click="openOrder(notification.data)"
              class="justify-center w-[70px] flex items-center bg-[#4ADE80] text-white rounded h-[18px] hover:bg-[#4ADE80]/40 text-[10px]"
            >
              Открыть
            </button>
          </template>
        </div>
      </template>
      <template
        v-if="notification.type === 'message-received'"
        class="text-[#797979] leading-none text-[11px]"
      >
        <div
          class="border-[0.5px] border-[#cececf] rounded-[10px_15px_15px_0px] w-[305px] h-[39px] p-[4px_10px] overflow-hidden"
          :class="{ 'bg-[#FECACA]/75': isFromSupport }"
        >
          <div
            v-if="isFromSupport"
            class="text-[10px] flex items-center gap-1 font-[500] text-[#797979] mb-[4px]"
          >
            Support ({{ supportName }}) <SupportIcon />
          </div>

          <div class="flex flex-col h-full">
            <div
              v-if="
                notification.data.attachments &&
                notification.data.attachments.length > 0 &&
                notification.data.attachments.some((att) => att.id)
              "
              class="flex items-center shrink-0"
            >
              <AddIconChat class="w-4 h-4 mr-1 shrink-0" />
              <div class="flex items-center gap-1 overflow-hidden">
                <span class="font-[500] text-[#797979] text-[10px] truncate">
                  {{ notification.data.attachments[0].original_name }}
                </span>
                <span class="font-[300] text-[#797979] text-[10px]">
                  {{ formatFileSize(notification.data.attachments[0].size) }}
                </span>
              </div>
            </div>

            <p class="text-[10px] font-[400] truncate text-black">
              {{ notification.data.text }}
            </p>
          </div>
        </div>
        <button
          @click="openChat(notification.data.order)"
          class="mt-1 justify-center w-[95px] flex items-center bg-[#43c2e8] text-white rounded h-[18px] hover:bg-[#4ADE80]/40 text-[11px]"
        >
          Ответить <MessageIcon class="w-[10px] h-[10px] ml-1" />
        </button>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useNotificationStore } from "@/stores/notificationStore";
import { useUserStore } from "@/stores/userStore";
import { useOrdersStore } from "@/stores/ordersStore";
import { useCashCityStore } from "@/stores/cashCityStore";
import { usePaymentMethodsStore } from "@/stores/paymentMethodsStore";
import NotificationAttention from "@/components/icons/NotificationAttention.vue";
import ChatIcon from "@/components/icons/ChatIcon.vue";
import Dispute from "@/components/icons/Dispute.vue";
import { playCloseNotificationSound } from "@/helpers/soundHelper";
import AddIconChat from "@/components/icons/AddIconChat.vue";
import MessageIcon from "@/components/icons/MessageIcon.vue";
import SupportIcon from "@/components/icons/SupportIcon.vue";

const props = defineProps({
  notification: {
    type: Object,
    required: true,
  },
  isPopup: {
    type: Boolean,
    default: false,
  },
});

const router = useRouter();

const userStore = useUserStore();
const ordersStore = useOrdersStore();
const cashCityStore = useCashCityStore();
const notificationStore = useNotificationStore();
const paymentMethodsStore = usePaymentMethodsStore();

const { user } = storeToRefs(userStore);
const { availableMethods } = storeToRefs(paymentMethodsStore);

const determineMethodType = (method) => {
  if (!method || !method.payment_method_id) return "unknown";

  const methodId = Number(method.payment_method_id);

  switch (methodId) {
    case 1:
      return "cash";
    case 2:
      return "bank";
    case 5:
      return "sbp";
    default:
      return "other";
  }
};

const getPaymentMethodName = (method) => {
  if (!method) return "Не указано";

  const methodType = determineMethodType(method);
  const methodId = Number(method.payment_method_id);
  const extraData = method.extra_data || {};

  switch (methodType) {
    case "cash":
      if (extraData.city_id) {
        const cityName = cashCityStore.getCityNameById(
          Number(extraData.city_id)
        );
        return cityName ? `Наличные (${cityName})` : "Наличные";
      }
      return "Наличные";

    case "bank":
      const bankName = extraData.bank_name ? ` (${extraData.bank_name})` : "";
      const accountNumber = extraData.number ? ` №${extraData.number}` : "";
      return `Расчетный счет${bankName}${accountNumber}`;

    case "sbp":
      return `СБП${extraData.bank ? ` (${extraData.bank})` : ""}`;

    case "other":
      const paymentMethod = availableMethods.value?.find(
        (m) => m.id === methodId
      );
      return paymentMethod?.name || "Неизвестный метод";

    default:
      return "Неизвестный метод";
  }
};

onMounted(async () => {
  if (isFromSupport.value) {
    try {
      const messageData = props.notification?.data?.data;
      if (!messageData?.user_id) return;

      const response = await $axios.get(
        apiList.users.getById.url.replace(":id", messageData.user_id)
      );
      supportName.value = response.data.data.name || "Unknown";
    } catch (error) {
      console.error("Error fetching support name:", error);
      supportName.value = "Unknown";
    }
  }
});

const getBankNameById = (methodId) => {
  const method = {
    payment_method_id: methodId,
    extra_data: props.notification.data.payment_method?.extra_data,
  };
  return getPaymentMethodName(method);
};

const accept = (orderId) => {
  const notificationId = props.notification.id;

  notificationStore.closePopupNotification(notificationId);

  notificationStore
    .markAsRead([notificationId])
    .then(() => ordersStore.updateOrderStatus(orderId, "accepted"))
    .then(() => {
      notificationStore.removeNotification(notificationId);
    })
    .catch((error) => {
      console.error("Error in accept flow:", error);
      alert(`Ошибка при принятии заказа: ${error.message}`);
      notificationStore.addNotification({ id: notificationId });
    });
};

const reject = (orderId) => {
  const notificationId = props.notification.id;
  notificationStore.closePopupNotification(notificationId);

  notificationStore
    .markAsRead([notificationId])
    .then(() => ordersStore.updateOrderStatus(orderId, "aborted"))
    .then(() => {
      notificationStore.removeNotification(notificationId);
    })
    .catch((error) => {
      console.error("Error in reject flow:", error);
      alert(`Ошибка при отклонении заказа: ${error.message}`);
      notificationStore.addNotification({ id: notificationId });
    });
};

const closeNotification = (notificationId) => {
  notificationStore.closePopupNotification(notificationId);

  notificationStore
    .markAsRead([notificationId])
    .then(() => {
      playCloseNotificationSound();
      notificationStore.removeNotification(notificationId);
    })
    .catch((error) => {
      console.error("Error marking notification as read:", error);
      notificationStore.removeNotification(notificationId);
    });
};

const openOrder = (order) => {
  const notificationId = props.notification.id;
  const orderId = order.id;

  notificationStore
    .markAsRead([notificationId])
    .then(() => {
      router.push({
        name: "orderFlow",
        query: {
          offerID: order.offer_id,
          orderID: orderId,
        },
      });
      notificationStore.removeNotification(notificationId);
    })
    .catch((error) => {
      console.error("Error marking notification as read:", error);
      router.push({
        name: "orderFlow",
        query: {
          offerID: order.offer_id,
          orderID: orderId,
        },
      });
      notificationStore.removeNotification(notificationId);
    });
};

const openChat = (order) => {
  const notificationId = props.notification.id;
  const orderId = order.id;

  notificationStore
    .markAsRead([notificationId])
    .then(() => {
      router.push({
        name: "orderFlow",
        query: {
          offerID: order.offer_id,
          orderID: orderId,
        },
      });
      notificationStore.removeNotification(notificationId);
    })
    .catch((error) => {
      console.error("Error marking notification as read:", error);
      router.push({
        name: "orderFlow",
        query: {
          offerID: order.offer_id,
          orderID: orderId,
        },
      });
      notificationStore.removeNotification(notificationId);
    });
};

const shouldDisplayNotification = computed(() => {
  if (props.notification.type === "message-received") {
    return props.notification.data.order.status.toUpperCase() !== "NEW";
  } else {
    return props.notification.data.status.toUpperCase() !== "NEW";
  }
});

const notificationIcon = computed(() => {
  if (props.notification.type === "message-received") {
    const orderStatus = props.notification.data.order.status.toUpperCase();
    if (orderStatus === "DISPUTE") {
      return Dispute;
    }
    return ChatIcon;
  }
  return NotificationAttention;
});

const isUserBuyer = (data) => {
  return data && data.buyer_id === user.value?.id;
};

const getCryptoCurrency = (data) => {
  return data?.cryptocurrency || "USDT";
};

const getNationalCurrency = (data) => {
  return data?.nationalCurrency || "RUB";
};

const getStatusText = (status) => {
  if (!status) return "";
  const statusTexts = {
    ACCEPTED: "Ожидается оплата",
    PAID: "Ожидается подтверждение оплаты",
    DISPUTE: "Открыт спор",
    ABORTED: "отменена",
    FINISHED: "завершена",
    DECLINED: "не принята",
    PENDING: "была создана",
  };
  return statusTexts[status.toUpperCase()] || status;
};

const isPendingOrder = (data) => data?.status?.toLowerCase() === "pending";

const isOrderCreator = (data) => {
  const currentUserId = user.value?.id;
  return (
    data?.user_id === currentUserId || data?.offer?.user_id === currentUserId
  );
};

const formatFileSize = (bytes) => {
  if (!bytes) return "0 B";

  const units = ["B", "KB", "MB", "GB"];
  let size = bytes;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  return `${size.toFixed(1)} ${units[unitIndex]}`;
};

const supportName = ref("");

const isFromSupport = computed(() => {
  const messageData = props.notification?.data?.data;
  if (!messageData) return false;

  const messageUserId = messageData.user_id;
  const order = messageData.order;

  if (!messageUserId || !order) return false;

  return (
    order.status === "dispute" &&
    messageUserId !== order.buyer_id &&
    messageUserId !== order.seller_id
  );
});
</script>
