<template>
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.67772 4.72652H13L10.883 2.60817C10.2014 1.92659 9.35242 1.43645 8.42136 1.18702C7.4903 0.937589 6.50998 0.937662 5.57896 1.18723C4.64794 1.4368 3.79902 1.92706 3.11755 2.60874C2.43608 3.29042 1.94608 4.13949 1.6968 5.07059M1.00067 11.5787V8.25644M1.00067 8.25644H4.32294M1.00067 8.25644L3.11702 10.3748C3.79859 11.0564 4.64758 11.5465 5.57864 11.7959C6.5097 12.0454 7.49002 12.0453 8.42104 11.7957C9.35206 11.5462 10.201 11.0559 10.8824 10.3742C11.5639 9.69253 12.0539 8.84347 12.3032 7.91237M13 1.40424V4.72519"
      stroke="#797979"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
