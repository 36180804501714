<template>
  <div
    class="fixed inset-0 bg-gray-900 z-[999999999] bg-opacity-50 flex flex-col items-center justify-center"
  >
    <ModalHeader
      @close="$emit('close')"
      class="w-[414px] mobile:w-full mobile:rounded-none"
    />
    <KYCFileUpload
      v-if="currentStep === 'upload'"
      :mode="mode"
      @upload-complete="handleUploadComplete"
    />
    <KYCPassportDetailsForm
      v-if="currentStep === 'passport'"
      :mode="mode"
      :uploadedFileIds="uploadedFileIds"
      :kyc-status="kycStatus"
      :kyc-comment="kycComment"
      @submission-complete="handleVerificationComplete"
    />
  </div>
</template>

<script setup>
import { ref } from "vue";
import ModalHeader from "./ModalDeal/ModalBlocks/ModalHeader.vue";
import KYCFileUpload from "./KYCFileUpload.vue";
import KYCPassportDetailsForm from "./KYCPassportDetailsForm.vue";

const props = defineProps({
  mode: {
    type: String,
    default: "create",
    validator: (value) => ["create", "update"].includes(value),
  },
  kycStatus: {
    type: String,
    default: "",
  },
  kycComment: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["close"]);

const currentStep = ref("upload");
const uploadedFileIds = ref([]);

const handleUploadComplete = (fileIds) => {
  uploadedFileIds.value = fileIds;
  currentStep.value = "passport";
};

const handleVerificationComplete = () => {
  emit("close");
};
</script>
