<template>
  <div class="pt-[15px] max-[767px]:pt-[10px]">
    <h2
      class="flex items-center justify-start mb-[5px] pl-[45px] text-[14px] max-[1439px]:text-[12px] max-[767px]:text-[14px] text-nowrap text-[#797979]/50"
    >
      <span class="flex items-center">
        <ArticleGameIcon
          class="w-6 h-6 mr-1.5 max-[1439px]:w-4 max-[1439px]:h-4 max-[767px]:w-6 max-[767px]:h-6"
        />
        Farming
      </span>
    </h2>
    <ul
      class="flex flex-col items-start justify-between text-[#000] font-[300] max-[1439px]:gap-y-[5px] max-[767px]:gap-y-[5px] text-[14px] max-[1439px]:text-[12px] max-[767px]:text-[14px] max-[1023px]:pb-[15px]"
    >
      <li class="text-nowrap w-full">
        <ArticleGameLoot
          class="mb-[8px] max-[1439px]:mb-[4px] max-[767px]:mb-[4px] w-full pl-[65px] max-[1439px]:pl-[30px] max-[767px]:!pl-[50px] transition duration-300 ease-in-out cursor-not-allowed text-[#797979]"
        />
      </li>
      <!-- <li class="text-nowrap w-full">
        <ArticleGameUmbrella
          class="mb-[8px] max-[1439px]:mb-[4px] max-[767px]:mb-[4px] w-full pl-[65px] max-[1439px]:pl-[30px] max-[767px]:!pl-[50px] cursor-not-allowed text-[#797979] transition duration-300 ease-in-out"
        />
      </li>
      <li class="text-nowrap w-full">
        <ArticleGameAirdrop
          class="mb-[8px] max-[1439px]:mb-[4px] max-[767px]:mb-[4px] w-full pl-[65px] max-[1439px]:pl-[30px] max-[767px]:!pl-[50px] cursor-not-allowed text-[#797979] transition duration-300 ease-in-out"
        />
      </li> -->
      <li class="text-nowrap w-full">
        <ArticleStacking
          class="mb-[8px] max-[1439px]:mb-[4px] max-[767px]:mb-[4px] w-full pl-[65px] max-[1439px]:pl-[30px] max-[767px]:!pl-[50px] cursor-not-allowed text-[#797979] transition duration-300 ease-in-out"
        />
      </li>
    </ul>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { useNavigationStore } from "../stores/marketStore";
import ArticleGameAirdrop from "./ArticleGameAirdrop.vue";
import ArticleGameLoot from "./ArticleGameLoot.vue";
import ArticleStacking from "./ArticleStacking.vue";
import ArticleGameUmbrella from "./ArticleGameUmbrella.vue";
import ArticleGameIcon from "./icons/ArticleGameIcon.vue";

const router = useRouter();
const navigationStore = useNavigationStore();

const navigateTo = (name, isComingSoon = false) => {
  if (!isComingSoon) {
    navigationStore.setView(name);
    router.push({ name: name });
  }
};
</script>
