
<template>
  <button @click="showMain">P2P Маркет</button>
</template>

<script setup>
import { useNavigationStore } from "../stores/marketStore";

const navigationStore = useNavigationStore();

function showMain() {
  navigationStore.setView('main');
}

</script>
