
<template>
<svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18.3647 5.52539H0.765137V16.2382H18.3647V5.52539Z" fill="#99A1CB" />
  <path d="M18.3647 10.1162H0.765137V12.4118H18.3647V10.1162Z" fill="#C7A659" />
  <path d="M11.4779 5.52539H7.65186V16.2382H11.4779V5.52539Z" fill="#FFD572" />
  <path d="M19.13 2.84668H0V5.90748H19.13V2.84668Z" fill="#4D5166" />
  <path d="M9.14043 8.9687L7.61003 7.8209L6.84483 8.9687C5.74093 7.42324 5.91611 5.30622 7.25909 3.96324L7.61003 3.6123H9.90563L9.55469 3.96324C8.21171 5.30622 8.03653 7.42324 9.14043 8.9687Z" fill="#BF4C53" />
  <path d="M9.98786 8.96822L11.5183 7.82042L12.2835 8.96822C13.3874 7.42275 13.2122 5.30573 11.8692 3.96276L11.5183 3.61182H9.22266L9.5736 3.96276C10.9166 5.30573 11.0918 7.42275 9.98786 8.96822Z" fill="#BF4C53" />
  <path d="M13.5166 4.57407L10.3301 3.99471V0.933915L13.5166 0.354563C14.0488 0.257813 14.5387 0.666669 14.5387 1.20762V3.72106C14.5387 4.26196 14.0488 4.67087 13.5166 4.57407Z" fill="#E65C64" />
  <path d="M5.61196 4.57408L8.79844 3.99473V0.933927L5.61196 0.354575C5.07976 0.257777 4.58984 0.66668 4.58984 1.20758V3.72102C4.58984 4.26197 5.07976 4.67088 5.61196 4.57408Z" fill="#E65C64" />
  <path d="M10.2004 4.37727H8.9292C8.64631 4.37727 8.41699 4.14795 8.41699 3.86506V1.06348C8.41699 0.780591 8.64631 0.55127 8.9292 0.55127H10.2004C10.4833 0.55127 10.7126 0.780591 10.7126 1.06348V3.86506C10.7126 4.14795 10.4833 4.37727 10.2004 4.37727Z" fill="#592427" />
</svg>
</template>
