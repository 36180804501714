<template>
  <div
    class="passport-details-form bg-white py-[20px] px-[20px] rounded-b-[20px] w-[414px] text-[#797979] mobile:w-full mobile:rounded-none mobile:h-full"
  >
    <h2 class="text-[18px] font-[600] mb-4 text-center">
      Заполните данные из паспорта
    </h2>

    <form @submit.prevent="handleSubmit">
      <div class="mb-3" v-for="field in passportFields" :key="field.name">
        <label :for="field.name" class="block text-[13px] font-[500] mb-1">{{
          field.label
        }}</label>
        <input
          :type="field.type"
          :id="field.name"
          v-model="formData[field.name]"
          class="w-full px-3 py-2 border border-gray-300 rounded-md text-[13px]"
          :placeholder="field.placeholder"
        />
      </div>
      <p class="text-[11px] mobile:text-[12px] text-center mb-4 text-[#FF6663]">
        Заполнять в абсолютной точности со всеми буквами, пробелами и точками,
        если таковые есть, как в паспорте
      </p>
      <button
        type="submit"
        class="w-full bg-[#4ADE80] text-white text-[13px] font-[500] px-4 py-2 rounded-[5px] mobile:w-full mobile:fixed mobile:bottom-0 mobile:rigth-0 mobile:left-0"
        :disabled="verificationStore.isLoading"
      >
        {{
          verificationStore.isLoading
            ? "Отправка..."
            : mode === "update"
            ? "Отправить повторно"
            : "Отправить на проверку"
        }}
      </button>
    </form>
    <div v-if="error" class="text-[#FF6663] text-center mt-4">
      {{ error }}
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useVerificationStore } from "@/stores/verification";

const props = defineProps({
  uploadedFileIds: {
    type: Array,
    required: true,
  },
  mode: {
    type: String,
    default: "create",
    validator: (value) => ["create", "update"].includes(value),
  },
  kycStatus: {
    type: String,
    default: "",
  },
  kycComment: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["submission-complete"]);
const verificationStore = useVerificationStore();

const passportFields = [
  { name: "last_name", label: "Фамилия", type: "text", placeholder: "Иванов" },
  { name: "first_name", label: "Имя", type: "text", placeholder: "Иван" },
  {
    name: "middle_name",
    label: "Отчество",
    type: "text",
    placeholder: "Иванович",
  },
  {
    name: "number",
    label: "Серия и номер паспорта",
    type: "text",
    placeholder: "3253812449",
  },
  {
    name: "birthdate",
    label: "Дата рождения",
    type: "date",
    placeholder: "31.01.1990",
  },
  {
    name: "issued_by",
    label: "Кем выдано",
    type: "text",
    placeholder: "ОТДЕЛОМ МВД Г. МОСКВА",
  },
  {
    name: "city",
    label: "Город рождения",
    type: "text",
    placeholder: "ГОР МОСКВА",
  },
  {
    name: "address",
    label: "Адрес прописки",
    type: "text",
    placeholder: "ул. Шастунова, дом 30, квартира 25",
  },
];

const formData = ref({
  last_name: "",
  first_name: "",
  middle_name: "",
  number: "",
  birthdate: "",
  issued_by: "",
  city: "",
  address: "",
});

const error = ref("");

const handleSubmit = () => {
  error.value = "";
  const submissionData = { ...formData.value };

  const promise =
    props.mode === "create"
      ? verificationStore.createKycApplication(submissionData)
      : verificationStore.updateKycApplication(submissionData);

  promise
    .then((result) => {
      if (result.success) {
        emit("submission-complete");
      } else {
        error.value =
          result.message || `Failed to ${props.mode} KYC application`;
      }
    })
    .catch((err) => {
      error.value =
        err.message ||
        `An error occurred while ${
          props.mode === "create" ? "creating" : "updating"
        } the application. Please try again.`;
      console.error(`Error ${props.mode}ing KYC application:`, err);
    });
};
</script>
