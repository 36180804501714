<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 28.2094C7.25136 28.2094 7.0129 28.1106 6.83709 27.9348C6.66127 27.7589 6.5625 27.5205 6.5625 27.2719V7.58435C6.5625 7.33571 6.66127 7.09725 6.83709 6.92144C7.0129 6.74562 7.25136 6.64685 7.5 6.64685C7.74864 6.64685 7.9871 6.74562 8.16291 6.92144C8.33873 7.09725 8.4375 7.33571 8.4375 7.58435V27.2719C8.4375 27.5205 8.33873 27.7589 8.16291 27.9348C7.9871 28.1106 7.74864 28.2094 7.5 28.2094ZM15 25.3969C14.7514 25.3969 14.5129 25.2981 14.3371 25.1223C14.1613 24.9464 14.0625 24.708 14.0625 24.4594V2.89685C14.0625 2.64821 14.1613 2.40975 14.3371 2.23394C14.5129 2.05812 14.7514 1.95935 15 1.95935C15.2486 1.95935 15.4871 2.05812 15.6629 2.23394C15.8387 2.40975 15.9375 2.64821 15.9375 2.89685V24.4594C15.9375 24.708 15.8387 24.9464 15.6629 25.1223C15.4871 25.2981 15.2486 25.3969 15 25.3969ZM22.5 18.8344C22.2514 18.8344 22.0129 18.7356 21.8371 18.5598C21.6613 18.3839 21.5625 18.1455 21.5625 17.8969V4.77185C21.5625 4.52321 21.6613 4.28475 21.8371 4.10894C22.0129 3.93312 22.2514 3.83435 22.5 3.83435C22.7486 3.83435 22.9871 3.93312 23.1629 4.10894C23.3387 4.28475 23.4375 4.52321 23.4375 4.77185V17.8969C23.4375 18.1455 23.3387 18.3839 23.1629 18.5598C22.9871 18.7356 22.7486 18.8344 22.5 18.8344Z"
      fill="#797979"
      fill-opacity="0.5"
    />
    <path
      d="M8.4375 13.2094H6.5625C6.04473 13.2094 5.625 13.6291 5.625 14.1469V24.4594C5.625 24.9771 6.04473 25.3969 6.5625 25.3969H8.4375C8.95527 25.3969 9.375 24.9771 9.375 24.4594V14.1469C9.375 13.6291 8.95527 13.2094 8.4375 13.2094Z"
      fill="#4ADE80"
    />
    <path
      d="M15.9375 6.64685H14.0625C13.5447 6.64685 13.125 7.06658 13.125 7.58435V12.2719C13.125 12.7896 13.5447 13.2094 14.0625 13.2094H15.9375C16.4553 13.2094 16.875 12.7896 16.875 12.2719V7.58435C16.875 7.06658 16.4553 6.64685 15.9375 6.64685Z"
      fill="#FF6663"
    />
    <path
      d="M23.4375 12.2719H21.5625C21.0447 12.2719 20.625 12.6916 20.625 13.2094V14.1469C20.625 14.6646 21.0447 15.0844 21.5625 15.0844H23.4375C23.9553 15.0844 24.375 14.6646 24.375 14.1469V13.2094C24.375 12.6916 23.9553 12.2719 23.4375 12.2719Z"
      fill="#FF6663"
    />
  </svg>
</template>
