<template>
  <div class="border-b">
    <div
      class="flex justify-between pt-[26px] pb-[28px] px-[16px] max-[1023px]:pb-[20px] max-[1439px]:px-[10px] max-[767px]:px-[10px] text-[#797979] text-[11px] font-[400]"
    >
      <button
        @click="goToTransactionsMake"
        class="flex flex-col items-center hover:text-[#333] justify-between"
      >
        <ArticleMethodsAddIcon class="svg" />
        Пополнить
      </button>
      <button
        @click="goToTransactionsTransfer"
        class="flex flex-col items-center hover:text-[#333] justify-between"
      >
        <ArticleMethodsSendIcon class="svg" />
        Перевести
      </button>
      <button
        class="flex flex-col items-center justify-between hover:text-[#333]"
        @click="goToTransactionsExchange"
      >
        <ArticleMethodsChangeIcon class="svg" />
        Обменять
      </button>
      <button
        @click="goToTransactionsWallet"
        class="flex flex-col items-center hover:text-[#333] justify-between"
      >
        <ArticleMethodsActiveIcon class="svg-cash" />
        Кошелёк
      </button>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import ArticleMethodsSendIcon from "../components/icons/ArticleMethodsSendIcon.vue";
import ArticleMethodsAddIcon from "../components/icons/ArticleMethodsAddIcon.vue";
import ArticleMethodsChangeIcon from "../components/icons/ArticleMethodsChangeIcon.vue";
import ArticleMethodsActiveIcon from "../components/icons/ArticleMethodsActiveIcon.vue";

const router = useRouter();
const emit = defineEmits(["navigation"]);

const goToTransactionsWallet = () => {
  router.push({ name: "TransactionsWallet" });
  emit("navigation");
};

const goToTransactionsTransfer = () => {
  router.push({ name: "TransactionsTransfer" });
  emit("navigation");
};

const goToTransactionsExchange = () => {
  router.push({ name: "TransactionsExchange" });
  emit("navigation");
};

const goToTransactionsMake = () => {
  router.push({ name: "TransactionsMake" });
  emit("navigation");
};
</script>

<style scoped>
@media (min-width: 1440px) {
  .svg {
    width: 30px;
    height: 30px;
  }
  .svg-cash {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 1439px) {
  .svg {
    width: 28px;
    height: 28px;
  }
  .svg-cash {
    width: 23px;
    height: 23px;
  }
}

@media (max-width: 767px) {
  .svg {
    width: 28px;
    height: 28px;
  }
  .svg-cash {
    width: 23px;
    height: 23px;
  }
}
</style>
