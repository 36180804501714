<template>
  <div
    class="fixed inset-0 bg-gray-900 bg-opacity-50 z-[9999] flex justify-center items-center"
  >
    <div
      class="flex flex-col bg-white rounded-3xl w-4/5 max-w-[414px] mobile:w-[100%] mobile:h-full mobile:max-w-full mobile:rounded-none absolute shadow-md text-[#797979]"
    >
      <ModalHeader @close="close" class="mobile:rounded-none"/>
      <div class="p-6 px-[55px] mobile:px-6 flex flex-col items-start ">
        <h2
          class="text-[16px] text-[#000] text-center w-full font-[500] mb-[12px] leading-[100%] text-[#797979]"
        >
          Подтвердите ваш E-mail
        </h2>
        <p class="text-[12px] font-[500] max-[767px]:text-[9px] mb-1">
          Откройте письмо в вашем почтовом сервисе
        </p>

        <p class="text-[10px] font-[500] mb-[3px]">
          Код отправлен на <span class="text-[#3C92C4]">{{ email }}</span>
        </p>
        <div class="relative w-full">
          <input
            ref="input"
            v-model="verificationCode"
            class="w-full p-3 pr-24 shadow-in rounded-lg text-sm"
            placeholder="Код подтверждения E-mail"
          />
        </div>
        <button
          @click="resendCode"
          type="button"
          :disabled="!canResend || isResending"
          class="text-[#797979] w-full flex gap-1 justify-end items-center text-sm mt-2 hover:underline disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <ResendIcon />
          <span v-if="isResending">отправка...</span>
          <span v-else-if="!canResend"
            >повторная отправка через {{ resendTimeout }}с</span
          >
          <span v-else>отправить повторно</span>
        </button>
        <p class="text-[10px] font-[500] text-[#FF6663] mt-1" v-if="error">
          {{ error }}
        </p>
        <button
          @click="verifyEmail"
          type="submit"
          class="bg-[#4ADE80] mt-4 mb-[14px] w-full hover:bg-[#4ADE80]/40 text-white font-[500] text-[16px] h-[40px] px-4 rounded-lg"
          :disabled="isVerifying"
        >
          {{ isVerifying ? "Подтверждение..." : "Подтвердить" }}
        </button>
        <button
          @click="goBack"
          class="bg-transparent text-nowrap h-[38px] text-[#ff9387] h-[40px] px-5 rounded border-none cursor-pointer text-base w-full shadow hover:shadow-md mb-[11px]"
        >
          Назад
        </button>
        <p class="text-[10px] font-[500] text-center px-[45px]">
          Не приходит код? Проверьте папку "Спам" или обратитесь в поддержку
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useAuthStore } from "@/stores/authStore";
import ModalHeader from "../ModalDeal/ModalBlocks/ModalHeader.vue";
import ResendIcon from "@/components/icons/ResendIcon.vue";

const props = defineProps({
  email: {
    type: String,
    required: true,
  },
});

const authStore = useAuthStore();
const verificationCode = ref("");
const isVerifying = ref(false);
const isPasting = ref(false);
const error = ref("");
const input = ref(null);
const isResending = ref(false);
const resendTimeout = ref(60);
const canResend = ref(true);

const emit = defineEmits(["close", "verified", "back"]);

const startResendTimer = () => {
  canResend.value = false;
  const timer = setInterval(() => {
    resendTimeout.value--;
    if (resendTimeout.value <= 0) {
      clearInterval(timer);
      canResend.value = true;
      resendTimeout.value = 60;
    }
  }, 1000);
};

const verifyEmail = () => {
  if (!verificationCode.value) {
    error.value = "Пожалуйста, введите код подтверждения";
    return;
  }

  isVerifying.value = true;
  error.value = "";

  authStore
    .verifyEmail(props.email, verificationCode.value)
    .then((response) => {
      console.log("Verification response:", response);

      if (
        response?.message === "User's email successfully verified." ||
        response.success ||
        response.status === 200
      ) {
        emit("verified");
      } else if (response.message) {
        error.value = response.message;
      }
    })
    .catch((err) => {
      console.error("Error verifying email:", err);
      error.value =
        err.response?.data?.message || "Ошибка при подтверждении email";
    })
    .finally(() => {
      isVerifying.value = false;
    });
};

const resendCode = () => {
  if (!canResend.value || isResending.value) return;

  isResending.value = true;
  error.value = "";

  authStore
    .resendVerificationCode(props.email)
    .then(() => {
      startResendTimer();
    })
    .catch((err) => {
      console.error("Error resending code:", err);
      error.value =
        err.response?.data?.message ||
        "Ошибка при отправке кода. Попробуйте позже.";
    })
    .finally(() => {
      isResending.value = false;
    });
};

const close = () => emit("close");
const goBack = () => emit("back");

defineExpose({ close });
</script>
