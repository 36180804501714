const playSound = (soundName) => {
  const soundPath = `https://umbrellax.io/assets/sound/${soundName}.mp3`;
  console.log('Путь к звуковому файлу:', soundPath);
  const audio = new Audio(soundPath);
  console.log('Audio объект создан:', audio);
  audio.onerror = (error) => {
    console.error('Error playing sound:', error);
  };
  audio.play().catch((error) => {
    console.error('Error playing sound:', error);
  });
};

export const playNotificationSound = () => {
  playSound('addNotification');
};

export const playCloseNotificationSound = () => {
  playSound('closeNotification');
};
