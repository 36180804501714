<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_90_364)">
      <path
        d="M10 20C15.5229 20 20 15.5229 20 10C20 4.47714 15.5229 0 10 0C4.47714 0 0 4.47714 0 10C0 15.5229 4.47714 20 10 20Z"
        fill="#0098EA"
      />
      <path
        d="M13.4143 5.58131H6.58517C5.32952 5.58131 4.53367 6.93578 5.16538 8.03074L9.38009 15.336C9.65513 15.813 10.3444 15.813 10.6194 15.336L14.835 8.03074C15.4658 6.93753 14.67 5.58131 13.4152 5.58131H13.4143ZM9.37667 13.1453L8.45877 11.3688L6.24399 7.40764C6.09788 7.1541 6.27834 6.82921 6.58431 6.82921H9.37581V13.1461L9.37667 13.1453ZM13.7538 7.40678L11.5399 11.3697L10.622 13.1453V6.82835H13.4135C13.7195 6.82835 13.8999 7.15324 13.7538 7.40678Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_90_364">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
