<template>
  <div
    class="relative wrap flex justify-between items-center lg:!pl-[6px] w-[75px]"
    ref="dropdownRef"
  >
    <div
      class="flex items-center cursor-pointer wrap-in"
      @click="toggleDropdown"
    >
      <span
        class="text-[12px] max-[1439px]:text-[10px] font-[400] text-[#000]"
        >{{ currencySelectionStore.selectedCurrency }}</span
      >
      <span
        class="ml-[2px] transition-transform duration-300"
        :class="{ 'rotate-180': isOpen }"
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.75 4.125L6 7.875L2.25 4.125"
            stroke="#797979"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    </div>
    <div
      v-if="isOpen"
      class="absolute wrap-in top-[20px] bg-white rounded-md shadow z-10 w-[50px] pb-0"
    >
      <div class="h-[200px] overflow-y-auto custom-scrollbar">
        <div
          v-for="currency in currencySelectionStore.availableCurrencies"
          :key="currency.ticker"
          class="flex items-center justify-center p-1 cursor-pointer hover:bg-gray-100 rounded-md text-[10px]"
          @click="selectCurrency(currency.ticker)"
        >
          <span>{{ currency.ticker }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { useCurrencySelectionStore } from "@/stores/currencySelectionStore";

const currencySelectionStore = useCurrencySelectionStore();
const isOpen = ref(false);
const dropdownRef = ref(null);

function toggleDropdown() {
  isOpen.value = !isOpen.value;
}

function selectCurrency(code) {
  currencySelectionStore.setCurrency(code);
  isOpen.value = false;
}

function handleClickOutside(event) {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
    isOpen.value = false;
  }
}

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});
</script>
