<template>
  <div
    class="bg-white py-[20px] px-[20px] rounded-b-[20px] w-[414px] mobile:w-full mobile:rounded-none mobile:h-full text-[#797979]"
  >
    <div class="flex justify-between items-center mb-2">
      <h2 class="text-[13px] mobile:text-[12px] font-[600]">
        Пожалуйста, загрузите изображения своих документов:
      </h2>
    </div>

    <div class="text-center flex flex-col items-center px-[60px] mb-[30px]">
      <VerificationModalIcon />
      <h4 class="font-[500] text-[12px] mobile:text-[12px]">
        Паспорт и Регистрация
      </h4>
      <p class="text-[10px] mobile:text-[11px]">
        Убедитесь, что вся информация хорошо читается и не засвечена, углы
        документа не обрезаны
      </p>

      <input
        type="file"
        accept=".jpg,.jpeg,.png"
        @change="(e) => handleFileChange(e, 'passport')"
        class="hidden"
        id="passport-upload"
      />
      <label
        for="passport-upload"
        class="flex gap-[5px] items-center mt-1 font-[300] text-[10px] mobile:text-[10px] px-4 py-2 rounded cursor-pointer"
      >
        Загрузите или перетащите файлы
        <Download />
      </label>

      <div v-if="passportFiles.length" class="mt-2 w-full">
        <div
          v-for="(file, index) in passportFiles"
          :key="file.name"
          class="flex justify-between items-center"
        >
          <p class="text-[10px] mobile:text-[11px] text-left text-[#797979]">
            {{ file.name }}
          </p>
          <button
            @click="removeFile('passport', index)"
            class="text-[#FF6663] text-[10px] mobile:text-[11px]"
          >
            Удалить
          </button>
        </div>
      </div>
      <p
        class="text-[10px] mobile:text-[11px] mt-2"
        :class="{
          'text-[#4ADE80]': isPassportComplete,
          'text-[#FF6663]': !isPassportComplete,
        }"
      >
        {{ passportFiles.length }}/2 фото загружено
      </p>
    </div>

    <div class="text-center flex flex-col items-center px-[60px]">
      <VerificationModalPasportIcon class="mb-[10px]" />
      <h4 class="font-[500] text-[12px] mobile:text-[12px]">
        Сфотографируйтесь в полный рост с паспортом в руках и отдельное фото
        паспорта на фоне сайта без лица
      </h4>
      <p class="text-[10px] mobile:text-[11px]">
        На фото должны быть полностью видны ваше лицо и вся информация на
        документе
      </p>

      <input
        type="file"
        accept=".jpg,.jpeg,.png"
        @change="(e) => handleFileChange(e, 'selfie')"
        class="hidden"
        id="selfie-upload"
      />
      <label
        for="selfie-upload"
        class="flex gap-[5px] items-center mt-1 font-[300] text-[10px] mobile:text-[10px] px-4 py-2 rounded cursor-pointer"
      >
        Загрузите или перетащите файлы
        <Download />
      </label>

      <div v-if="selfieFiles.length" class="mt-2 w-full">
        <div
          v-for="(file, index) in selfieFiles"
          :key="file.name"
          class="flex justify-between items-center"
        >
          <p class="text-[10px] mobile:text-[11px] text-left text-[#797979]">
            {{ file.name }}
          </p>
          <button
            @click="removeFile('selfie', index)"
            class="text-[#FF6663] text-[10px] mobile:text-[11px]"
          >
            Удалить
          </button>
        </div>
      </div>
      <p
        class="text-[10px] mobile:text-[11px] mt-2"
        :class="{
          'text-[#4ADE80]': isSelfieComplete,
          'text-[#FF6663]': !isSelfieComplete,
        }"
      >
        {{ selfieFiles.length }}/2 фото загружено
      </p>
    </div>

    <ul class="text-[11px] font-[300] mt-4 px-[20px]">
      <li>• Загрузите файл формата .jpg, .jpeg или .png не более 25MB</li>
      <li>• Полноразмерное, неотредактированное фото</li>
      <li>• Документ размещён на однотонном фоне</li>
      <li>• Информация хорошо читаема</li>
      <li>• Фотография цветная, сделанная при хорошем освещении.</li>
    </ul>

    <div v-if="error" class="text-[#FF6663] text-center mt-4">
      {{ error }}
    </div>

    <div class="flex justify-center mt-4">
      <button
        @click="handleUploadSubmit"
        class="w-[250px] text-white text-[13px] font-[500] px-4 py-2 rounded-[5px] mobile:h-[40px] mobile:text-[14px] mobile:font-[500] mobile:w-full mobile:fixed mobile:bottom-0"
        :class="{
          'bg-[#4ADE80]': isSubmitEnabled,
          'bg-[#797979]': !isSubmitEnabled || isLoading,
        }"
        :disabled="!isSubmitEnabled || isLoading"
      >
        {{ isLoading ? "Загрузка..." : "Перейти к заполнению данных" }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useVerificationStore } from "@/stores/verification";
import Download from "./icons/Download.vue";
import VerificationModalIcon from "./icons/VerificationModalIcon.vue";
import VerificationModalPasportIcon from "./icons/VerificationModalPasportIcon.vue";

defineProps({
  mode: {
    type: String,
    default: "create",
    validator: (value) => ["create", "update"].includes(value),
  },
});

const emit = defineEmits(["upload-complete"]);
const verificationStore = useVerificationStore();

const passportFiles = ref([]);
const selfieFiles = ref([]);
const error = ref("");
const isLoading = ref(false);

const MAX_FILE_SIZE = 25 * 1024 * 1024;
const ALLOWED_FILE_TYPES = ["image/jpeg", "image/jpg", "image/png"];

const isPassportComplete = computed(() => passportFiles.value.length === 2);
const isSelfieComplete = computed(() => selfieFiles.value.length === 2);
const isSubmitEnabled = computed(
  () => isPassportComplete.value && isSelfieComplete.value
);

const handleFileChange = (event, fileType) => {
  const files = Array.from(event.target.files);
  const validFiles = files.filter((file) => {
    if (file.size > MAX_FILE_SIZE) {
      error.value = `Файл ${file.name} слишком большой. Максимальный размер 25MB.`;
      return false;
    }
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      error.value = `Файл ${file.name} имеет неверный формат. Разрешены только .jpg, .jpeg или .png`;
      return false;
    }
    return true;
  });

  if (fileType === "passport") {
    passportFiles.value = [...passportFiles.value, ...validFiles].slice(0, 2);
  } else {
    selfieFiles.value = [...selfieFiles.value, ...validFiles].slice(0, 2);
  }

  if (
    (fileType === "passport" && passportFiles.value.length > 2) ||
    (fileType === "selfie" && selfieFiles.value.length > 2)
  ) {
    error.value = `Выбрано слишком много файлов. Используются только первые 2 файла.`;
  } else {
    error.value = "";
  }
};

const removeFile = (fileType, index) => {
  if (fileType === "passport") {
    passportFiles.value.splice(index, 1);
  } else if (fileType === "selfie") {
    selfieFiles.value.splice(index, 1);
  }
};

const handleUploadSubmit = () => {
  const allFiles = [...passportFiles.value, ...selfieFiles.value];
  isLoading.value = true;
  error.value = "";

  verificationStore
    .uploadFiles(allFiles)
    .then((fileIds) => {
      if (fileIds.length === allFiles.length) {
        verificationStore.setUploadedFileIds(fileIds);
        emit("upload-complete", fileIds);
      } else {
        throw new Error("Not all files were uploaded successfully");
      }
    })
    .catch((err) => {
      error.value = "Error uploading files. Please try again: " + err.message;
      console.error("Error uploading files:", err);
    })
    .finally(() => {
      isLoading.value = false;
    });
};
</script>
