import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useCashCityStore = defineStore("cashCity", () => {
  const cities = [
    { id: 1, name: "Дубай" },
    { id: 2, name: "Москва" },
    { id: 3, name: "Санкт-Петербург" },
    { id: 4, name: "Новосибирск" },
    { id: 5, name: "Екатеринбург" },
    { id: 6, name: "Казань" },
    { id: 7, name: "Нижний Новгород" },
    { id: 8, name: "Челябинск" },
    { id: 9, name: "Самара" },
    { id: 10, name: "Омск" },
    { id: 11, name: "Ростов-на-Дону" },
    { id: 12, name: "Уфа" },
    { id: 13, name: "Красноярск" },
    { id: 14, name: "Воронеж" },
    { id: 15, name: "Пермь" },
    { id: 16, name: "Волгоград" },
    { id: 17, name: "Краснодар" },
    { id: 18, name: "Саратов" },
    { id: 19, name: "Тюмень" },
    { id: 20, name: "Тольятти" },
    { id: 21, name: "Ижевск" },
    { id: 22, name: "Барнаул" },
    { id: 23, name: "Ульяновск" },
    { id: 24, name: "Иркутск" },
    { id: 25, name: "Хабаровск" },
    { id: 26, name: "Ярославль" },
    { id: 27, name: "Владивосток" },
    { id: 28, name: "Томск" },
    { id: 29, name: "Оренбург" },
    { id: 30, name: "Кемерово" },
    { id: 31, name: "Новокузнецк" },
    { id: 32, name: "Рязань" },
    { id: 33, name: "Астрахань" },
    { id: 34, name: "Набережные Челны" },
    { id: 35, name: "Пенза" },
    { id: 36, name: "Липецк" },
    { id: 37, name: "Киров" },
    { id: 38, name: "Чебоксары" },
    { id: 39, name: "Тула" },
    { id: 40, name: "Калининград" },
    { id: 41, name: "Балашиха" },
    { id: 42, name: "Курск" },
    { id: 43, name: "Севастополь" },
    { id: 44, name: "Сочи" },
    { id: 45, name: "Ставрополь" },
    { id: 46, name: "Улан-Удэ" },
    { id: 47, name: "Тверь" },
    { id: 48, name: "Магнитогорск" },
    { id: 49, name: "Брянск" },
    { id: 50, name: "Иваново" },
    { id: 51, name: "Белгород" },
    { id: 52, name: "Сургут" },
    { id: 53, name: "Владимир" },
    { id: 54, name: "Нижний Тагил" },
    { id: 55, name: "Архангельск" },
    { id: 56, name: "Чита" },
    { id: 57, name: "Калуга" },
    { id: 58, name: "Смоленск" },
    { id: 59, name: "Волжский" },
    { id: 60, name: "Курган" },
    { id: 61, name: "Орёл" },
    { id: 62, name: "Череповец" },
    { id: 63, name: "Вологда" },
    { id: 64, name: "Саранск" },
    { id: 65, name: "Якутск" },
    { id: 66, name: "Мурманск" },
    { id: 67, name: "Подольск" },
    { id: 68, name: "Тамбов" },
    { id: 69, name: "Стерлитамак" }
  ];

  const getCityById = (id) => cities.find((city) => city.id === id);
  const getCityNameById = (id) => {
    const city = getCityById(id);
    return city ? city.name : "";
  };

  return {
    cities,
    getCityById,
    getCityNameById,
  };
});
