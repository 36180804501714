import { defineStore } from "pinia";
import { ref } from "vue";
import { useRouter } from "vue-router";

export const useNavigationStore = defineStore("navigation", () => {
  const router = useRouter();
  const currentView = ref(localStorage.getItem("currentView") || "main");
  const currentParams = ref({});

  const setView = (view, params = {}) => {
    if (view !== "landing") {
      currentView.value = view;
      currentParams.value = params;
      localStorage.setItem("currentView", view);
    }
  };

  const initializeView = () => {
    const savedView = localStorage.getItem("currentView");

    const currentPath = window.location.pathname;

    if (currentPath === "/") {
      return;
    }

    if (savedView) {
      if (savedView === "editorAd") {
        router.push({ name: "myAds" });
      } else {
        router.push({ name: savedView });
      }
    } else {
      router.push({ name: "main" });
    }
  };

  const showMain = () => {
    setView("main");
  };

  const showUserProfile = () => {
    setView("userProfile");
  };

  const showAdmin = () => {
    setView("adminPanel");
  };

  const navigateToDealFlow = (offer) => {
    const route = offer.type === "sell" ? "buyFlow" : "sellFlow";
    router.push({
      name: route,
      params: { offerId: offer.id, postData: offer },
      query: { amount: offer.min_amount },
    });
  };

  const showRules = (ruleName = "userAgreement") => {
    setView("rules");
    router
      .push({ name: ruleName })
      .then(() => {})
      .catch((err) => {});
  };

  return {
    currentView,
    currentParams,
    setView,
    initializeView,
    showMain,
    showUserProfile,
    showAdmin,
    navigateToDealFlow,
    showRules,
  };
});
