import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { $axios, apiList } from "../api";
import { useNotificationStore } from "./notificationStore";

export const useUserStore = defineStore("user", () => {
  const notificationStore = useNotificationStore();
  const user = ref(null);
  const isAuthenticated = ref(false);
  const userProfiles = ref(new Map());
  const isFetchingUser = ref(false);
  const lastFetchTimestamp = ref(0);

  const CRYPTO_ORDER = ["UMBRELLA", "USDT", "TON", "BTC", "ETH"];
  const FETCH_COOLDOWN = 1000;
  const userStatusMap = {
    newer: "Новичок",
    merchant: "Мерчант",
    banned: "Заблокированный",
  };

  const balances = computed(() => user.value?.balance || {});
  const isUserVerified = computed(() => !!user.value?.kyc_verified_at);
  const fullName = computed(() => {
    if (!user.value) return "";
    return `${user.value.name || ""} ${user.value.surname || ""}`.trim();
  });
  const userStatus = computed(() => {
    const status = user.value?.status || "newer";
    return userStatusMap[status] || "Новичок";
  });
  const username = computed(() => user.value?.username || "Гость");
  const userLevel = computed(() => user.value?.level || 0);
  const nextLevel = computed(() => (user.value?.level || 0) + 1);
  const cryptoBalances = computed(() => {
    return CRYPTO_ORDER.map((ticker) => ({
      ticker,
      amount: balances.value[ticker] || 0,
    }));
  });
  const userOrders = computed(() => {
    if (!user.value?.notifications?.data) return [];
    return user.value.notifications.data
      .filter(
        (notification) =>
          notification.type === "order-created" ||
          notification.type === "order-status-change"
      )
      .map((notification) => notification.data.order);
  });
  const hasOrderForOffer = computed(
    () => (offerId) =>
      userOrders.value.some((order) => order.offer_id === offerId)
  );

  const fetchUser = () => {
    const now = Date.now();

    if (isFetchingUser.value) {
      return new Promise((resolve) => {
        const checkInterval = setInterval(() => {
          if (!isFetchingUser.value) {
            clearInterval(checkInterval);
            resolve(user.value);
          }
        }, 100);
      });
    }

    if (now - lastFetchTimestamp.value < FETCH_COOLDOWN) {
      return Promise.resolve(user.value);
    }

    isFetchingUser.value = true;
    lastFetchTimestamp.value = now;

    return $axios
      .request(apiList.auth.getUser)
      .then((response) => {
        user.value = response.data.user;
        isAuthenticated.value = true;
        return response.data.user;
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        isAuthenticated.value = false;
        user.value = null;
        throw error;
      })
      .finally(() => {
        isFetchingUser.value = false;
      });
  };

  const getUserById = (userId) => {
    if (userProfiles.value.has(userId)) {
      return Promise.resolve(userProfiles.value.get(userId));
    }

    return $axios
      .request({
        ...apiList.users.getById,
        url: apiList.users.getById.url.replace(":id", userId),
      })
      .then((response) => {
        const userData = {
          ...response.data.user,
          status: response.data.user?.status || "newer",
          isOwner: false,
          isPremium: false,
          kyc_verified_at: response.data.user?.kyc_verified_at || null,
          positiveReviews: response.data.user?.profile_stats?.rating_good || 0,
          negativeReviews: response.data.user?.profile_stats?.rating_bad || 0,
          online: response.data.user?.online || false,
        };
        userProfiles.value.set(userId, userData);
        return userData;
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        const errorData = { error: "Failed to load user data", id: userId };
        userProfiles.value.set(userId, errorData);
        throw error;
      });
  };

  const updateUserField = (field, value) => {
    if (!user.value?.id) {
      return Promise.reject(new Error("No user ID available"));
    }

    return $axios
      .request({
        ...apiList.users.getById,
        url: apiList.users.getById.url.replace(":id", user.value.id),
        method: "PUT",
        data: { [field]: value },
      })
      .then(() => {
        user.value = { ...user.value, [field]: value };
        return user.value;
      });
  };

  const logout = () => {
    return $axios.request(apiList.auth.logout).then(() => {
      user.value = null;
      isAuthenticated.value = false;
      notificationStore.$reset();
    });
  };

  const setAuthenticated = (value) => {
    isAuthenticated.value = value;
    if (!value) {
      user.value = null;
    }
    return Promise.resolve(value);
  };

  const updateUser = (userData) => {
    user.value = { ...user.value, ...userData };
    isAuthenticated.value = true;
    return Promise.resolve(user.value);
  };

  const formatCryptoAmount = (amount) =>
    amount === 0 ? "0" : amount.toString();
  const formatBalance = (value) => value.toFixed(0);
  const clearUserProfileCache = () => userProfiles.value.clear();
  const removeUserProfile = (userId) => userProfiles.value.delete(userId);

  return {
    user,
    isAuthenticated,
    isFetchingUser,

    balances,
    cryptoBalances,
    isUserVerified,
    fullName,
    username,
    userStatus,
    userLevel,
    nextLevel,
    userOrders,
    hasOrderForOffer,

    fetchUser,
    getUserById,
    updateUserField,
    logout,
    setAuthenticated,
    updateUser,
    formatCryptoAmount,
    formatBalance,
    clearUserProfileCache,
    removeUserProfile,

    CRYPTO_ORDER,
  };
});
