<template>
  <div
    class="flex relative flex-col justify-center items-center pb-[12px] px-[40px] max-[1439px]:px-[10px] max-[767px]:px-[30px] border-b"
  >
    <div class="flex flex-col gap-[6px] mb-[12px] w-full">
      <div class="flex items-start w-full flex-wrap">
        <p
          class="text-[14px] max-[1439px]:text-[12px] max-[767px]:text-[12px] text-[#797979] font-[300]"
        >
          Здравствуйте,
        </p>
        <span
          class="text-[14px] max-[1439px]:text-[12px] max-[767px]:text-[12px] font-[500]"
        >
          &nbsp;{{ user?.username || "Гость" }}
        </span>
      </div>

      <div class="flex items-center w-full">
        <p
          class="text-[14px] max-[1439px]:text-[12px] max-[767px]:text-[12px] text-nowrap text-[#797979] font-[300]"
        >
          Общий баланс&nbsp;
        </p>
        <span
          v-if="!hideBalance"
          class="text-[#2ecc71] text-[14px] max-[1439px]:text-[12px] max-[767px]:text-[12px] font-light"
        >
          {{ calculateTotalBalance }}
        </span>
        <span
          v-else
          class="text-[#2ecc71] text-[14px] max-[1439px]:text-[12px] max-[767px]:text-[12px] font-light"
          >****&nbsp;</span
        >
        <button @click="hideBalance = !hideBalance" class="ml-2">
          <EyeOffIcon
            v-if="hideBalance"
            class="h-[14px] w-[14px] max-[1439px]:w-[11px] max-[1439px]:h-[11px] max-[767px]:w-[11px] max-[767px]:h-[11px] mr-1 text-[#797979]"
          />
          <EyeIcon
            v-else
            class="h-[14px] w-[14px] max-[1439px]:w-[11px] max-[1439px]:h-[11px] max-[767px]:w-[11px] max-[767px]:h-[11px] mr-1 text-[#797979]"
          />
        </button>
        <ArticleCurrency />
      </div>

      <div class="flex items-center w-full">
        <p
          class="text-[14px] max-[1439px]:text-[12px] max-[767px]:text-[12px] text-[#797979] font-[300]"
        >
          Статус:
        </p>
        <span
          class="text-[14px] max-[1439px]:text-[12px] max-[767px]:text-[12px] font-[500]"
        >
          &nbsp;{{ formatUserStatus }}&nbsp;&nbsp;
        </span>
        <BuyVipBtn @click="openModalVip" />
      </div>
    </div>
    <BuyVipModal v-if="isModalOpen" @close="closeModalVip" />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/userStore";
import { useRateStore } from "@/stores/rateStore";
import BuyVipBtn from "./BuyVipBtn.vue";
import ArticleCurrency from "./ArticleCurrency.vue";
import EyeIcon from "../components/icons/EyeIcon.vue";
import EyeOffIcon from "../components/icons/EyeOffIcon.vue";
import BuyVipModal from "./BuyVipModal.vue";

const userStore = useUserStore();
const rateStore = useRateStore();
const { user } = storeToRefs(userStore);

const isModalOpen = ref(false);
const hideBalance = ref(false);

const calculateTotalBalance = computed(() => {
  if (!user.value?.balance) return "0";
  const total = rateStore.totalBalance("USD");
  return total.toFixed(0);
});

const formatUserStatus = computed(() => {
  const statusMap = {
    newer: "Новичок",
    merchant: "Мерчант",
  };
  return statusMap[user.value?.status || "newer"] || "Новичок";
});

const openModalVip = () => {
  isModalOpen.value = true;
};

const closeModalVip = () => {
  isModalOpen.value = false;
};

onMounted(() => {
  if (user.value?.balance) {
    rateStore.setBalances(user.value.balance);
    rateStore.startAutoUpdate();
  }
});

onUnmounted(() => {
  rateStore.stopAutoUpdate();
});
</script>
