<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Ethereum"
    role="img"
    viewBox="0 0 512 512"
    width="800px"
    height="800px"
    fill="#000000"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0" />

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      <rect width="512" height="512" rx="15%" fill="#ffffff" />

      <path fill="#62688F" d="m256 362v107l131-185z" />

      <path fill="#62688F" d="m256 41l131 218-131 78-132-78" />

      <path fill="#8A92B2" d="m256 41v158l-132 60m0 25l132 78v107" />

      <path fill="#454A75" d="m256 199v138l131-78" />

      <path fill="#62688F" d="m124 259l132-60v138" />
    </g>
  </svg>
</template>
