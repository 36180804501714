<template>
  <svg
  class="w-[16px] h-[16px]"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.25"
      y="0.25"
      width="15.5"
      height="15.5"
      rx="2.75"
      fill="white"
      stroke="#797979"
      stroke-width="0.5"
    />
  </svg>
</template>
