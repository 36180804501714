import { defineStore } from "pinia";
import { ref } from "vue";
import { $axios } from "../api";

export const useSubscriptionStore = defineStore("subscription", () => {
  const loading = ref(false);
  const error = ref(null);

  const purchaseVipPlan = () => {
    loading.value = true;
    error.value = null;

    return new Promise((resolve, reject) => {
      $axios
        .post("/subscriptions", {
          plan: "vip",
        })
        .then((response) => {
          loading.value = false;
          resolve(response.data);
        })
        .catch((err) => {
          loading.value = false;
          error.value =
            err.response?.data?.error || "Failed to purchase VIP subscription";

          console.log("API Error Details:", {
            message: err.response?.data?.message,
            status: err.response?.status,
            statusText: err.response?.statusText,
            url: err.config?.url,
            method: err.config?.method,
            data: err.config?.data,
            response: err.response?.data,
          });

          reject(error.value);
        });
    });
  };

  const purchasePremiumPlan = () => {
    loading.value = true;
    error.value = null;

    return new Promise((resolve, reject) => {
      $axios
        .post("/subscriptions", {
          plan: "premium",
        })
        .then((response) => {
          loading.value = false;
          resolve(response.data);
        })
        .catch((err) => {
          loading.value = false;
          error.value =
            err.response?.data?.error ||
            "Failed to purchase Premium subscription";

          console.log("API Error Details:", {
            message: err.response?.data?.message,
            status: err.response?.status,
            statusText: err.response?.statusText,
            url: err.config?.url,
            method: err.config?.method,
            data: err.config?.data,
            response: err.response?.data,
          });

          reject(error.value);
        });
    });
  };

  return {
    loading,
    error,
    purchaseVipPlan,
    purchasePremiumPlan,
  };
});
