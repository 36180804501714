import { defineStore } from "pinia";
import { ref } from "vue";
import { $axios, apiList } from "../api";
import { useUserStore } from "./userStore";

export const useVerificationStore = defineStore("verification", () => {
  const isLoading = ref(false);
  const error = ref(null);
  const kycApplication = ref(null);
  const userStore = useUserStore();

  const getKycApplication = () => {
    if (!userStore.user) {
      return Promise.resolve(null);
    }

    isLoading.value = true;
    error.value = null;

    const userId = userStore.user.id;
    const url = apiList.kyc.get.url.replace("{id}", userId);

    return $axios({
      url: url,
      method: apiList.kyc.get.method,
    })
      .then((response) => {
        kycApplication.value = response.data.kyc_application;
        return {
          success: true,
          message: "KYC application fetched successfully",
          data: response.data,
        };
      })
      .catch((err) => {
        if (err.response?.status === 404) {
          kycApplication.value = null;
          return {
            success: true,
            message: "No KYC application found",
            data: null,
          };
        }

        error.value =
          err.response?.data?.message ||
          err.message ||
          "An error occurred while fetching the KYC application";
        return {
          success: false,
          message: error.value,
          error: err,
        };
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  const uploadFiles = (files) => {
    isLoading.value = true;
    error.value = null;

    const fileUploadPromises = files.map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      return $axios({
        url: apiList.files.upload.url,
        method: apiList.files.upload.method,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
    });

    return Promise.all(fileUploadPromises)
      .then((responses) => {
        const fileIds = responses.map(
          (response) => response.data.attachment.id
        );
        return fileIds;
      })
      .catch((err) => {
        error.value = "Error uploading files";
        throw err;
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  const uploadedFileIds = ref([]);

  const setUploadedFileIds = (ids) => {
    uploadedFileIds.value = ids;
  };

  const createKycApplication = (formData) => {
    if (uploadedFileIds.value.length === 0) {
      return Promise.reject(new Error("No files have been uploaded"));
    }

    isLoading.value = true;
    error.value = null;

    const docs = uploadedFileIds.value.slice(0, 2);
    const photos = uploadedFileIds.value.slice(2);

    const extraData = {
      last_name: formData.last_name || "",
      first_name: formData.first_name || "",
      middle_name: formData.middle_name || "",
      number: formData.number || "",
      birthdate: formData.birthdate || "",
      issued_by: formData.issued_by || "",
      city: formData.city || "",
      address: formData.address || "",
    };

    return $axios({
      url: apiList.kyc.create.url,
      method: apiList.kyc.create.method,
      data: { docs, photos, extra_data: extraData },
    })
      .then((response) => {
        if (response.data && response.data.kyc_application) {
          kycApplication.value = response.data.kyc_application;
          return {
            success: true,
            message: "KYC application created successfully",
            data: response.data.kyc_application,
          };
        } else {
          throw new Error("Unexpected server response");
        }
      })
      .catch((err) => {
        console.error(
          "Detailed error:",
          err.response?.data,
          err.response?.status,
          err.response?.headers
        );
        error.value =
          err.response?.data?.message ||
          err.message ||
          "An error occurred during KYC application creation";
        throw err;
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  const updateKycApplication = (formData) => {
    if (uploadedFileIds.value.length === 0) {
      return Promise.reject(new Error("No files have been uploaded"));
    }

    if (!kycApplication.value?.id) {
      return Promise.reject(new Error("No KYC application ID found"));
    }

    isLoading.value = true;
    error.value = null;

    const docs = uploadedFileIds.value.slice(0, 2);
    const photos = uploadedFileIds.value.slice(2);

    const extraData = {
      last_name: formData.last_name || "",
      first_name: formData.first_name || "",
      middle_name: formData.middle_name || "",
      number: formData.number || "",
      birthdate: formData.birthdate || "",
      issued_by: formData.issued_by || "",
      city: formData.city || "",
      address: formData.address || "",
    };

    const url = apiList.kyc.update.url.replace("{id}", kycApplication.value.id);

    const requestData = {
      docs,
      photos,
      extra_data: extraData,
      status: "waiting",
    };

    return $axios({
      url: url,
      method: "PUT",
      data: requestData,
    })
      .then((response) => {
        if (response.data && response.data.kyc_application) {
          kycApplication.value = response.data.kyc_application;
          return {
            success: true,
            message: "KYC application updated successfully",
            data: response.data.kyc_application,
          };
        } else {
          throw new Error("Unexpected server response");
        }
      })
      .catch((err) => {
        error.value =
          err.response?.data?.message ||
          err.message ||
          "An error occurred during KYC application update";
        throw err;
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  return {
    isLoading,
    error,
    kycApplication,
    uploadFiles,
    createKycApplication,
    updateKycApplication,
    uploadedFileIds,
    setUploadedFileIds,
    getKycApplication,
  };
});
