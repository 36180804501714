<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 25.3926L22.5 29.8737L30 19.4179M42 23.8989C42 26.4243 40.74 28.6588 38.814 30.0091C39.0215 31.1897 38.9403 32.4027 38.5772 33.5453C38.214 34.688 37.5798 35.7267 36.728 36.5734C35.8777 37.4216 34.8346 38.0532 33.6871 38.4148C32.5396 38.7764 31.3215 38.8573 30.136 38.6507C29.4444 39.6315 28.5257 40.4319 27.4576 40.9842C26.3895 41.5364 25.2035 41.8242 24 41.8233C21.464 41.8233 19.22 40.5686 17.864 38.6507C16.6785 38.8571 15.4604 38.7761 14.313 38.4146C13.1655 38.053 12.1224 37.4215 11.272 36.5734C10.4202 35.7267 9.78596 34.688 9.42285 33.5453C9.05973 32.4027 8.97849 31.1897 9.186 30.0091C8.20105 29.3205 7.39724 28.4056 6.84265 27.342C6.28807 26.2784 5.99902 25.0974 6 23.8989C6 21.3736 7.26 19.139 9.186 17.7887C8.97849 16.6082 9.05973 15.3952 9.42285 14.2526C9.78596 13.1099 10.4202 12.0712 11.272 11.2244C12.1224 10.3764 13.1655 9.74489 14.313 9.38331C15.4604 9.02173 16.6785 8.94075 17.864 9.14722C18.5557 8.16652 19.4744 7.36619 20.5425 6.81395C21.6106 6.2617 22.7965 5.9738 24 5.97461C26.536 5.97461 28.78 7.22931 30.136 9.14722C31.3215 8.94075 32.5396 9.02173 33.687 9.38331C34.8345 9.74489 35.8776 10.3764 36.728 11.2244C37.5796 12.0713 38.2138 13.11 38.5769 14.2526C38.94 15.3953 39.0213 16.6082 38.814 17.7887C39.799 18.4774 40.6028 19.3923 41.1573 20.4559C41.7119 21.5195 42.001 22.7005 42 23.8989Z"
      stroke="#4ADE80"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
