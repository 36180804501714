import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useOrdersStore } from "./ordersStore";
import { useAuthStore } from "./authStore";
import { $axios, apiList } from "../api";

export const useChatStore = defineStore("chat", () => {
  const messages = ref([]);
  const loading = ref(false);
  const error = ref(null);
  const currentOrderId = ref(null);
  const activeChats = ref(new Set());
  const isWebSocketInitialized = ref(false);
  const wsConnections = ref(new Map());

  const ordersStore = useOrdersStore();
  const authStore = useAuthStore();

  const currentUserName = computed(() => authStore.user.username);
  const currentUserId = computed(() => authStore.user.id);

  function initializeWebSocket() {
    if (!window.Echo || isWebSocketInitialized.value) {
      return Promise.resolve();
    }

    cleanupConnections();

    const activeOrders = ordersStore.orders.filter(
      (order) =>
        !["finished", "annulled", "aborted", "declined"].includes(order.status)
    );

    const subscribePromises = activeOrders.map((order) =>
      subscribeToOrderChat(order.id)
    );

    return Promise.all(subscribePromises)
      .then(() => {
        isWebSocketInitialized.value = true;
      })
      .catch((error) => {
        console.error("Failed to initialize WebSocket:", error);
      })
      .then(() => {
        return Promise.resolve();
      });
  }

  function cleanupConnections() {
    return new Promise((resolve) => {
      wsConnections.value.forEach((connection, orderId) => {
        if (window.Echo) {
          window.Echo.leave(`chats.${orderId}`);
        }
      });
      wsConnections.value.clear();
      activeChats.value.clear();
      resolve();
    });
  }

  function clearMessages() {
    messages.value = [];
  }

  function setCurrentOrderId(orderId) {
    if (currentOrderId.value === orderId) {
      return Promise.resolve();
    }

    if (!window.Echo) {
      return Promise.resolve();
    }

    const order = ordersStore.orders.find((o) => o.id === orderId);
    if (
      !order ||
      ["finished", "annulled", "aborted", "declined"].includes(order.status)
    ) {
      return Promise.resolve();
    }

    clearMessages();
    currentOrderId.value = orderId;

    if (!wsConnections.value.has(orderId)) {
      return subscribeToOrderChat(orderId);
    }

    return Promise.resolve();
  }

  function subscribeToOrderChat(orderId) {
    return new Promise((resolve) => {
      if (wsConnections.value.has(orderId)) {
        resolve();
        return;
      }

      if (!window.Echo) {
        resolve();
        return;
      }

      const channel = window.Echo.private(`chats.${orderId}`);

      channel.listen("MessageCreated", (e) => {
        handleNewWebSocketMessage(e);
      });

      wsConnections.value.set(orderId, channel);
      activeChats.value.add(orderId);
      resolve();
    });
  }

  function handleNewWebSocketMessage(messageData) {
    if (!messageData || !messageData.data) return;

    const chatMessage = messageData.data;

    if (chatMessage.order_id !== currentOrderId.value) {
      return;
    }

    if (chatMessage.files && Array.isArray(chatMessage.files)) {
      chatMessage.files = chatMessage.files.map((file) => ({
        id: file.id,
        name: file.name || file.original_name,
        url: file.url,
      }));
    } else if (
      chatMessage.attachments &&
      Array.isArray(chatMessage.attachments)
    ) {
      chatMessage.files = chatMessage.attachments.map((attachment) => ({
        id: attachment.id,
        name: attachment.name || attachment.original_name,
        url: attachment.url,
      }));
    }

    const exists = messages.value.some((msg) => msg.id === chatMessage.id);
    if (!exists) {
      addMessage(chatMessage);
    }
  }

  function reconnectChat(orderId) {
    console.log(`Attempting to reconnect chat ${orderId}`);
    unsubscribeFromOrderChat(orderId).then(() => {
      setTimeout(() => {
        subscribeToOrderChat(orderId).catch((error) => {
          console.error(`Failed to reconnect chat ${orderId}:`, error);
        });
      }, 1000);
    });
  }

  function unsubscribeFromOrderChat(orderId) {
    return new Promise((resolve) => {
      if (!window.Echo || !wsConnections.value.has(orderId)) {
        resolve();
        return;
      }

      console.log(`Unsubscribing from chat ${orderId}`);
      window.Echo.leave(`chats.${orderId}`);
      wsConnections.value.delete(orderId);
      activeChats.value.delete(orderId);
      resolve();
    });
  }

  function subscribeToUserChats(orders) {
    return new Promise((resolve, reject) => {
      if (!orders || !Array.isArray(orders)) {
        resolve();
        return;
      }

      const unsubscribePromises = Array.from(activeChats.value).map(
        (chatId) => {
          const order = orders.find((o) => o.id === chatId);
          if (
            !order ||
            ["finished", "annulled", "aborted", "declined"].includes(
              order.status
            )
          ) {
            return unsubscribeFromOrderChat(chatId);
          }
          return Promise.resolve();
        }
      );

      const activeOrders = orders.filter(
        (order) =>
          !["finished", "annulled", "aborted", "declined"].includes(
            order.status
          )
      );

      Promise.all(unsubscribePromises)
        .then(() => {
          const subscribePromises = activeOrders.map((order) =>
            subscribeToOrderChat(order.id)
          );
          return Promise.all(subscribePromises);
        })
        .then(() => resolve())
        .catch(reject);
    });
  }

  const groupedMessages = computed(() => {
    const groups = {};
    messages.value.forEach((message) => {
      const date = new Date(message.created_at).toISOString().split("T")[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
    });
    return groups;
  });

  function uploadFile(file) {
    const formData = new FormData();
    formData.append("file", file);

    return $axios({
      url: apiList.files.upload.url,
      method: apiList.files.upload.method,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data.attachment.id;
      })
      .catch((err) => {
        error.value = "Не удалось загрузить файл";
        throw err;
      });
  }

  function deleteFile(fileId) {
    return $axios({
      url: apiList.files.delete.url.replace(":id", fileId),
      method: apiList.files.delete.method,
    })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        error.value = "Не удалось удалить файл";
        throw err;
      });
  }

  function sendMessage(messageData) {
    if (
      !messageData.text &&
      (!messageData.files || messageData.files.length === 0)
    ) {
      return Promise.resolve();
    }

    return $axios({
      url: apiList.messages.send.url,
      method: apiList.messages.send.method,
      data: {
        order_id: messageData.order_id,
        text: messageData.text || "",
        files: messageData.files || [],
      },
    })
      .then((response) => {
        if (response.data && response.data.chat_message) {
          addMessage(response.data.chat_message);
        }
        return response.data;
      })
      .catch((err) => {
        error.value = "Не удалось отправить сообщение";
        throw err;
      });
  }

  function addMessage(messageData) {
    const newMessage = {
      id: messageData.id,
      sender: messageData.user_id,
      text: messageData.text || "",
      created_at: messageData.created_at,
      files: messageData.files || messageData.attachments || [],
    };

    const messageIndex = messages.value.findIndex(
      (msg) => msg.id === newMessage.id
    );
    if (messageIndex === -1) {
      messages.value.push(newMessage);
    } else {
      messages.value[messageIndex] = newMessage;
    }

    messages.value = [...messages.value];
  }

  function fetchMessages(orderId) {
    if (!orderId) {
      return Promise.reject(new Error("Order ID is required"));
    }

    loading.value = true;
    error.value = null;

    return $axios({
      url: apiList.messages.getByOrderId.url.replace("{id}", orderId),
      method: apiList.messages.getByOrderId.method,
    })
      .then((response) => {
        if (response.data && Array.isArray(response.data.chat_messages)) {
          messages.value = response.data.chat_messages.map((msg) => ({
            id: msg.id,
            sender: msg.user_id,
            text: msg.text || "",
            created_at: msg.created_at,
            files: msg.files || msg.attachments || [],
          }));
        } else {
          throw new Error("Unexpected response format");
        }
        loading.value = false;
      })
      .catch((err) => {
        error.value = "Не удалось загрузить сообщения";
        loading.value = false;
        throw err;
      });
  }

  return {
    messages,
    groupedMessages,
    loading,
    error,
    currentOrderId,
    currentUserId,
    fetchMessages,
    sendMessage,
    setCurrentOrderId,
    handleNewWebSocketMessage,
    uploadFile,
    clearMessages,
    deleteFile,
    addMessage,
    reconnectChat,
    subscribeToOrderChat,
    unsubscribeFromOrderChat,
    subscribeToUserChats,
    activeChats,
    initializeWebSocket,
    isWebSocketInitialized,
  };
});
